<template>
  <el-form ref="accountRef" :model="accountLoginForm" size="large" class="u-m-t-15" @submit.native.prevent>
    <el-form-item prop="memberCode">
      <el-input
        v-model="accountLoginForm.memberCode"
        type="text"
        :prefix-icon="User"
        placeholder="请输入账号"
      ></el-input>
    </el-form-item>
    <el-form-item prop="memberPwd">
      <el-input
        v-model="accountLoginForm.memberPwd"
        type="password"
        :prefix-icon="Unlock"
        placeholder="请输入密码"
      ></el-input>
    </el-form-item>
    <el-form-item prop="verificationCode">
      <el-input
        v-model="accountLoginForm.verificationCode"
        :prefix-icon="CircleCheck"
        type="text"
        placeholder="请输入验证码"
        style="max-width: 75%"
      ></el-input>
      <el-image class="code-img" :src="captchaImg" @click="onCaptcha"></el-image>
    </el-form-item>
    <div class="u-center">
      <el-button
        class="sumbit-btn"
        type="primary"
        @click="submitAccountLoginForm(accountRef)"
        @keydown.enter="keyDown(accountRef)"
        >登录</el-button
      >
      <div class="ohter-box">
        <el-checkbox v-model="isRemember">记住账号密码</el-checkbox>
        <span style="text-align: right">
          <router-link to="/register">注册</router-link> | <router-link to="/retrievePassword">忘记密码</router-link>
        </span>
      </div>
      <div class="ohter-box">
        <a
          class="el-button el-button--primary el-button--large"
          style="color: #fff; font-size: 16px"
          href="https://kmbf.kainonly.com:8443/"
          target="_blank"
          >进入旧版</a
        >
        <el-button
          class="el-button el-button--primary el-button--large"
          style="width: 100px"
          type="primary"
          @click="quickLogin()"
          >体验号登录</el-button
        >
      </div>
    </div>
  </el-form>
  <!-- 会员充值 -->
  <member-recharge-dialog ref="rechargeRef"></member-recharge-dialog>
</template>

<script setup>
import { h, ref, reactive, onMounted, onUnmounted } from 'vue';
import { User, Unlock, CircleCheck } from '@element-plus/icons-vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import memberRechargeDialog from '@/components/recharge-modal/index.vue';
import { useRouter } from 'vue-router';
import { accountLogin, useCaptcha, handleAnnualFee } from '@/api/user';
import { userStore } from '@/store/user';
const router = useRouter();
const useInfo = userStore();
const Base64 = require('js-base64').Base64;
//账号登录
const accountLoginForm = reactive({
  memberCode: '',
  memberPwd: '',
  verificationCode: ''
});
const captchaImg = ref();
// 是否下次自动登录
const isRemember = ref(false);

onMounted(() => {
  onCaptcha();
  //绑定监听事件
  window.addEventListener('keydown', keyDown);
  let memberCode = localStorage.getItem('memberCode');
  if (memberCode) {
    accountLoginForm.memberCode = localStorage.getItem('memberCode');
    accountLoginForm.memberPwd = Base64.decode(localStorage.getItem('memberPwd')); // base64解密
    isRemember.value = true;
  }
});

onUnmounted(() => {
  //销毁事件
  window.removeEventListener('keydown', keyDown, false);
});
//验证码
const onCaptcha = async () => {
  const reader = new FileReader();
  reader.onloadend = () => {
    captchaImg.value = reader.result;
  };
  const { data } = await useCaptcha();
  reader.readAsDataURL(data);
  // captchaImg.value = 'data:image/png;base64,' + data.imgJpeg;
};

const accountRef = ref();

// 会员充值
const rechargeRef = ref();

//体验号登录
const quickLogin = () => {
  accountLoginForm.memberCode = '9996';
  accountLoginForm.memberPwd = '123456';
  submitAccountLoginForm();
};

//账号登录
const submitAccountLoginForm = () => {
  //记住密码
  if (isRemember.value == true) {
    let memberPwd = Base64.encode(accountLoginForm.memberPwd); // base64解密
    localStorage.setItem('memberCode', accountLoginForm.memberCode);
    localStorage.setItem('memberPwd', memberPwd);
  } else {
    localStorage.removeItem('memberCode');
    localStorage.removeItem('memberPwd');
  }
  //登录
  useInfo
    .accountLogin(accountLoginForm)
    .then(res => {
      if (userStore().code == 0) {
        ElMessage({
          message: '登录成功',
          type: 'success',
          duration: 1000
        });
        setTimeout(() => {
          router.push({
            path: '/notice'
          });
        }, 1000);
      } else {
        onCaptcha();
      }
    })
    .catch(e => {
      onCaptcha();
    });
};
//点击回车键登录
const keyDown = e => {
  if (e.keyCode == 13) {
    submitAccountLoginForm();
  }
};
</script>

<style lang="scss">
@import '@/assets/style/login.scss';
.code-img {
  width: 108px;
  height: 46px;
  margin-bottom: 5px;
}
</style>
