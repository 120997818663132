<template>
	<div class="account-info">
		<div class="title">账户基本信息：</div>
		<div class="info">
			<div class="u-tr">
				<div class="u-td">项目</div>
				<div class="u-td">金额</div>
			</div>
			<div class="u-tr">
				<div class="u-td">余额</div>
				<div class="u-td">{{account.amount.toFixed(2)}}</div>
			</div>
			<div class="u-tr">
				<div class="u-td">可用金额</div>
				<div class="u-td">{{account.monetary.toFixed(2)}}</div>
			</div>
			<div class="u-tr">
				<div class="u-td">冻结金额</div>
				<div class="u-td">{{account.frozen.toFixed(2)}}</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted
	} from 'vue';
	import {
		getMemberInfo
	} from '@/api/user';
	const account = reactive({
		monetary: 0,
		frozen: 0,
		dayDiscount: 0,
		discount: 0,
		usedDiscount: 0,
		allDiscount: 0,
		amount:0
	})

	onMounted(async () => {
		await initData()
	});

	/**
	 * 初始化数据
	 * @param {无}
	 */
	const initData = async () => {
		await memberInfo()
	}

	//获取会员信息
	const memberInfo = () => {
		return getMemberInfo().then(res => {
			Object.assign(account, res.data.memberInfo);
		})
	}
</script>

<style lang="scss" scoped>
	.account-info {
		.title {
			font-size: 18px;
			font-weight: bold;
			color: #3A3A3A;
			line-height: 55px;
		}

		.info {
			.u-tr {
				display: flex;

				.u-td {
					border: 1px solid #EFEFEF;
					width: 200px;
					text-align: center;
					font-size: 16px;
					font-weight: 400;
					color: #505050;
					height: 42px;
					line-height: 42px;
				}
			}
		}
		.money-btn{
			margin-top:30px;
		}
	}
</style>