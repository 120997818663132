<template>
	<el-dialog v-model="dialogVisible" :close-on-click-modal="false" title="抬头信息" draggable width="550px">
		<div class="invoice-title-info">
			<div class="u-flex-row">
				<div class="u-lable">发票类型：</div>
				<div class="u-text" v-if="invoiceInfoForm.invoiceType==1">普票</div>
				<div class="u-text" v-if="invoiceInfoForm.invoiceType==2">增值税专用票</div>
			</div>
			<div class="u-flex-row">
				<div class="u-lable">抬头类型：</div>
				<div class="u-text" v-if="invoiceInfoForm.titleType==1">个人</div>
				<div class="u-text" v-if="invoiceInfoForm.titleType==2">单位</div>
			</div>
			<div class="u-flex-row">
				<div class="u-lable">发票材质：</div>
				<div class="u-text" v-if="invoiceInfoForm.invoiceMode==1">电子版</div>
				<!-- <div class="u-text" v-if="invoiceInfoForm.invoiceMode==2">纸质版</div> -->
			</div>
			<div class="u-flex-row">
				<div class="u-lable">发票抬头：</div>
				<div class="u-text">{{invoiceInfoForm.invoiceTitle}}</div>
			</div>
			<div v-if="invoiceInfoForm.titleType==2">
				<div class="u-flex-row">
					<div class="u-lable">单位税号：</div>
					<div class="u-text">{{invoiceInfoForm.taxNum}}</div>
				</div>
				<!-- <div class="u-flex-row">
					<div class="u-lable">开户银行：</div>
					<div class="u-text">{{invoiceInfoForm.bankName}}</div>
				</div>
				<div class="u-flex-row">
					<div class="u-lable">银行账号：</div>
					<div class="u-text">{{invoiceInfoForm.bankAccount}}</div>
				</div>
				<div class="u-flex-row">
					<div class="u-lable">注册地址：</div>
					<div class="u-text">{{invoiceInfoForm.address}}</div>
				</div>
				<div class="u-flex-row">
					<div class="u-lable">注册电话：</div>
					<div class="u-text">{{invoiceInfoForm.phone}}</div>
				</div> -->
			</div>
			<div class="u-flex-row">
				<div class="u-lable">邮箱：</div>
				<div class="u-text">{{invoiceInfoForm.email}}</div>
			</div>
			<!-- <div v-if="invoiceInfoForm.titleType==2&&invoiceInfoForm.invoiceMode==2">
				<div class="u-flex-row">
					<div class="u-lable">收票人姓名：</div>
					<div class="u-text">{{invoiceInfoForm.deliveryContact}}</div>
				</div>
				<div class="u-flex-row">
					<div class="u-lable">收票地址：</div>
					<div class="u-text">{{invoiceInfoForm.deliveryAddress}}</div>
				</div>
			</div>
			<div class="u-flex-row">
				<div class="u-lable">收票手机号：</div>
				<div class="u-text">{{invoiceInfoForm.deliveryPhone}}</div>
			</div> -->
			<div class="u-flex-row">
				<div class="u-lable">是否默认抬头：</div>
				<div class="u-text" v-if="invoiceInfoForm.isDefaultTitle==0">否</div>
				<div class="u-text" v-if="invoiceInfoForm.isDefaultTitle==1">是</div>
			</div>
		</div>
	</el-dialog>
</template>

<script setup>
	import {
		reactive,
		ref
	} from 'vue';
	import {
		memberInvoiceTitleInfo
	} from '@/api/user';
	const invoiceInfoForm = reactive({
		id: '',
		invoiceType: 1,
		titleType: 1,
		invoiceTitle: '',
		invoiceMode: 1,
		email: '',
		taxNum: '',
		bankName: '',
		bankAccount: '',
		address: '',
		phone: '',
		deliveryContact: '',
		deliveryAddress: '',
		deliveryPhone: '',
		isDefaultTitle: 0
	});
	const getInvoiceTitleInfo = () => {
		return memberInvoiceTitleInfo({id: invoiceInfoForm.id}).then(res => {
			Object.assign(invoiceInfoForm, res.data.memberInvoiceTitleInfo);
		})

	}

	//打开弹窗时
	const dialogVisible = ref(false);
	const onChangeVisible = (Id) => {
		invoiceInfoForm.id = Id;
		getInvoiceTitleInfo()
		dialogVisible.value = true;
	};

	defineExpose({
		onChangeVisible
	});
</script>

<style lang="scss" scoped>
	.invoice-title-info{
		.u-flex-row{
			font-size:18px;
			line-height:36px;
			.u-lable{
				display: inline-block;
				width:180px;
				text-align:right;
				font-weight: bold;
			}
		}
	}
</style>