<template>
  <div class="main-wrap">
    <el-row :gutter="18">
      <!-- 左侧分类 -->
      <el-col :span="4" style="padding: 0 5px">
        <ProductLeft
          :sale_date="state.sale_date"
          :sale_type="state.sale_type"
          :keywords="state.keywords"
          :color="state.color"
          :grade="state.grade"
          :brand_code="state.brand_code"
          v-model:main_category="state.main_category"
          v-model:sub_category="state.sub_category"
          v-model:label="state.label"
        ></ProductLeft>
      </el-col>

      <!-- 产品列表 -->
      <el-col :span="product_list_span" style="padding: 0 0 0 5px">
        <ProductMiddle
          v-model:main_category="state.main_category"
          v-model:sub_category="state.sub_category"
          v-model:label="state.label"
          @updateSaleType="onSaleTypeChange"
        >
        </ProductMiddle>
      </el-col>
      <!-- 右测占位 -->
      <el-col :span="order_list_span" style="padding: 0 5px">
        <div
          v-show="showOrderList"
          :style="fixed_order_detail ? productDetailStyle.container : productDetailStyleX.container"
          @click="toggleOrderDetail"
        >
          <div :style="productDetailStyleX.mask" v-if="!fixed_order_detail"></div>
          <div
            :style="fixed_order_detail ? productDetailStyle.content : productDetailStyleX.content"
            :class="ani"
            @click.stop="s"
          >
            <i
              class="iconfont"
              :class="fixed_order_detail ? 'icon-pin2-fill' : 'icon-pin-fill'"
              style="font-size: 0.15rem"
              @click="fixedOrderDetail"
            ></i>
            <ProductRight :sale_type="state.sale_type"></ProductRight>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 产看订单列表 -->
    <div
      style="
        position: fixed;
        right: 22px;
        bottom: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 999;
      "
      @click="toggleOrderDetail"
      v-if="!fixed_order_detail"
    >
      <el-avatar shape="circle" :size="60" style="background-color: #fe5ba3">
        <el-icon :size="50">
          <Tickets />
        </el-icon>
      </el-avatar>
      <div
        style="
          background-color: white;
          box-shadow: 0 2px 8px rgba(50, 50, 51, 0.16);
          padding: 3px;
          position: relative;
          top: -3px;
        "
      >
        订单列表</div
      >
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, watch, computed, onMounted, onBeforeUnmount } from 'vue';
import ProductLeft from './components/product-left/index.vue';
import ProductMiddle from './components/product-middle/index.vue';
import ProductRight from './components/product-right/index.vue';
import { SALE_TYPE } from '@/common/const';
import { Tickets } from '@element-plus/icons-vue';
import { getAfterNoonSaleDate } from '@/common';

const state = reactive({
  sale_date: getAfterNoonSaleDate(),
  sale_type: SALE_TYPE.AFTERNOON,
  keywords: '',
  color: '',
  grade: '',
  brand_code: '',
  main_category: '',
  sub_category: '',
  label: ''
});

function onSaleTypeChange(event) {
  console.log('销售类型改变', event);
  state.sale_type = event;
}

const showOrderList = ref(false);
const product_list_span = ref(19);
const order_list_span = ref(1);
const fixed_order_detail = ref(false);
const direction = ref('rtl');
const ani = ref('scale-out-hor-right');
const productDetailStyle = reactive({
  container: {
    // position: 'fixed',
    // inset: 0,
    // 'z-index': 999
  },
  mask: {
    /**position: 'absolute', 'background-color': 'black', opacity: 0.5, width: '100%', height: '100%'**/
  },
  content: {
    // position: 'absolute'
  }
});
const productDetailStyleX = reactive({
  container: {
    position: 'fixed',
    inset: 0,
    'z-index': 999
  },
  mask: { position: 'absolute', 'background-color': 'black', opacity: 0.5, width: '100%', height: '100%' },
  content: {
    position: 'absolute',
    right: 0,
    'background-color': 'white',
    height: '100%',
    width: '40%',
    padding: '20px'
  }
});

// watch(
//   () => showOrderList.value,
//   (newValue, oldValue) => {
//     if (newValue) {
//       state.order_sale_type = state.sale_type;
//     } else {
//       state.order_sale_type = 0;
//     }
//   }
// );

function fixedOrderDetail() {
  fixed_order_detail.value = !fixed_order_detail.value;
  if (fixed_order_detail.value) {
    product_list_span.value = 13;
    order_list_span.value = 7;
  } else {
    product_list_span.value = 19;
    order_list_span.value = 1;
  }
}

function toggleOrderDetail() {
  console.log('dsffsdfs');
  if (showOrderList.value) {
    setTimeout(() => {
      showOrderList.value = false;
    }, 1000 * 0.5);
    ani.value = 'scale-out-hor-right';
  } else {
    showOrderList.value = true;
    ani.value = 'scale-in-hor-right';
  }
}
</script>

<style lang="scss">
@import '@/assets/style/same.scss';

.main-wrap {
  .el-table__expand-icon {
    display: none !important;
  }

  .el-table__cell {
    padding: 0 !important;
  }

  .el-button {
    padding: 12px 8px;
  }

  .el-table__placeholder {
    display: none !important;
  }

  .el-table__row--level-1 {
    height: 70px !important;
  }

  .el-checkbox-button .el-checkbox-button__inner {
    width: 42px !important;
    line-height: 18px !important;
  }
}
</style>

<style lang="scss" scoped>
.product-name {
  font-size: 16px;
  font-weight: bold;
}

.product-img {
  position: relative;
  width: 27px;
  height: 36px;
  cursor: pointer;

  &__tag {
    position: absolute;
    bottom: 1px;
    left: 1px;
    width: 20px;
    height: 20px;
    font-size: 8px !important;
    z-index: 10;
  }
}

:v-deep .fixed-order-list {
  margin-top: 54px !important;
  box-shadow: 0 0 0 #ffffff;
}
</style>
