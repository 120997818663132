<template>
  <el-dialog v-model="addressVisible" title="地址管理" center width="60%" append-to-body>
    <el-table :data="state.data" border empty-text="还没有收货地址！">
      <el-table-column prop="LinkMan" label="收货人" align="center"></el-table-column>
      <el-table-column prop="Phone" label="手机" align="center"></el-table-column>
      <el-table-column prop="Province,City,Area,Street" label="所在地区" align="center">
        <template #default="scope">
          <span>{{ scope.row.Province + scope.row.City + scope.row.Area + scope.row.Street }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="DetailAddr" label="详细地址" align="center"></el-table-column>
      <el-table-column prop="DeliveryWay" label="物流方式" align="center"></el-table-column>
      <el-table-column prop="IsDefault" label="是否为默认" align="center">
        <template #default="scope">
          <span :class="{ 'u-color-primary': scope.row.IsDefault == 1 }">{{
            scope.row.IsDefault == 1 ? '是' : '否'
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="operation" label="操作" align="center">
        <template #default="scope">
          <el-button type="primary" link @click="onSelectAddress(scope.row)">{{
            scope.row.Id == state.addressId ? '当前地址' : '选择'
          }}</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script setup>
import { memberAddressApproveList, updateDefaultMemberAddress } from '@/api/user';
import { reactive, ref } from 'vue';

const emit = defineEmits(['updateMember']);

const state = reactive({
  data: [],
  addressId: ''
});

/**
 * 获取销地址列表
 * @param {无}
 */
const getAddressList = async () => {
  const {
    data: { records }
  } = await memberAddressApproveList();
  state.data = records;
};

/**
 * 选择地址
 * @param {object} event
 */
const onSelectAddress = async event => {
  if (event.Id == state.addressId) return;
  await updateDefaultMemberAddress({ id: event.Id });
  emit('updateMember', true);
  addressVisible.value = false;
};

// 弹窗显示
const addressVisible = ref(false);
const onAddressVisible = addressId => {
  state.addressId = addressId;
  getAddressList();
  addressVisible.value = true;
};

// 暴露给父组件
defineExpose({
  onAddressVisible
});
</script>

<style lang="scss" scoped>
.u-color-primary {
  color: #fe5ba3;
  font-weight: bold;
}
</style>