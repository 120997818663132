<template>
	<el-dialog v-model="dialogVisible" :close-on-click-modal="false" title="普通会员升级为VIP会员攻略">
		<div class="vip-info">
			<h2>普通会员升级为VIP会员条件</h2>
			<div>（1）每周采购（每个自然周都有采购记录）</div>
			<div>（2）正价率50%以上</div>
			<div>（3）每周采购额都能达到平均每周400元的标准</div>
			<div>（4）门店货源的采购量、采购额的50%以上来自缤纷鲜花网</div>
			<div>
				当无连续采购时，重新开始累积，法定节假日、疫情等特殊情况均定为连续采购。不满足条件时标黄，满足标绿，当“我的VIP情况”中3条均已成为绿底时，您就已经具备了升级为VIP会员的基础条件，会有“申请VIP”的按钮
			</div>
			<h2>VIP权益</h2>
			<div>（1）VIP会员免除200元的会员年费，普通会员均需正常缴纳会员费</div>
			<div>（2）VIP可提前半小时订购，提前半小时为VIP会员专属订购时间，普通会员无法订购，VIP可优先于普通会员抢购花材，尤其是新奇品种、专销品种和热销品种。</div>
			<div>（3）VIP会员可优先提交订单，优先配货</div>
			<div>（4）新鲜花材优先挑选、享受品质差异化服务</div>
			<h2>保持VIP</h2>
			<h3>VIP不是终身的。</h3>
			<div>当您的采购数据不再满足升级VIP的4个条件之一，您将会被降级为普通会员。降级后若上次缴纳的年费已到期，系统将自动为您从余额中扣取年费。</div>
			<h2>我的VIP情况</h2>
			<div>目前您是：<span>{{ memberVipInfo.memberType }}</span></div>
			<div v-for="(item, index) in memberVipInfo.msgs" :key="index" v-html="item.msg"></div>
		</div>
	</el-dialog>
</template>

<script setup>
	import { reactive, ref } from "vue";
	import { memberVipPreInfo } from "@/api/user"

	/**
	 * 取我的VIP情况
	 * @param {无}
	 */
	const memberVipInfo = reactive({
		memberType: '',
		msgs: []
	})
	const getMemberVipPreInfo = async () => {
		const { data } = await memberVipPreInfo()
		memberVipInfo.memberType = data.memberType
		memberVipInfo.msgs = data.msgs.map(item => {
			// 数字高亮显示
			item.msg = `<div>${item.msg}</div>`.replace(/([+\-]?[0-9]+(\.[0-9]+)?)/g, 
				"<span style='color: #FC4D9A; font-weight: bold'>$1</span>")
			return item
		})
	}

	/**
	 * 弹窗
	 * @param {无}
	 */
	const dialogVisible = ref(false);
	const onChangeVisible = () => {
		getMemberVipPreInfo()
		dialogVisible.value = true;
	};

	defineExpose({
		onChangeVisible
	});
</script>

<style lang="scss" scoped>
	.vip-info {
		h2 {
			line-height: 64px;
		}

		div {
			font-size: 18px;
			line-height: 36px;

			span {
				color: #FC4D9A;
				font-weight: bold;
			}
		}
	}
</style>