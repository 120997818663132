<template>
	<div class="flower w" v-infinite-scroll="onBottoming" :infinite-scroll-distance="50" :infinite-scroll-delay="500">
		<div class="tab-wrap">
			<ul class="nav-box">
				<li :class="{'selected-link' : state.params.shareType == item.value}" 
					v-for="(item, index) in tabs" :key="index" @click="onClickTab(item)">{{ item.name }}</li>
			</ul>
			<div class="share-box">
				<el-button type="primary" @click="router.push('/index/share')">我要分享</el-button>
			</div>
		</div>

		<!-- 列表 -->
		<flower-list :list="state.list"></flower-list>
	</div>

</template>

<script setup>
	import flowerList from './components/flower-list.vue';
	import { reactive, onMounted, ref } from 'vue';
	import { useRouter } from 'vue-router';
	import { webShareProductList } from '@/api/flower'

	const router = useRouter()

	const tabs = reactive([
		{ value: 0, name: '基地内容' },
		{ value: 1, name: '出货实拍' },
		{ value: 2, name: '花艺分享' }
	])

	onMounted(() => {
		getFlowerList()
	})

	/**
	 * 触底加载
	 * @param {无}
	 */
	const onBottoming = () => {
		if (state.total == state.list.length) {
			return
		}
		state.params.limit.conditions.pageStart++
		getFlowerList()
	}

	const state = reactive({
		params: {
			shareType: 0,
			limit: {							// 分页参数
				enable: true,
				conditions: {
					pageStart: 1,
					pageLength: 10
				}
			}
		},
		list: [],
		total: 0
	})
	/**
	 * 取我看花列表
	 * @param {无}
	 */
	const getFlowerList = async () => {
		const { data: { records, filterAmount } } = await webShareProductList(state.params)
		state.list = [...state.list, ...records]
		state.total = filterAmount
	}

	/**
	 * 点击标签
	 * @param {object} event 
	 */
	const onClickTab = (event) => {
		if (state.params.shareType == event.value) {
			return
		}
		state.params.shareType = event.value
		onResetData()
	}

	/**
	 * 重置数据
	 * @param {无}
	 */
	const onResetData = () => {
		state.list = []
		state.params.limit.conditions.pageStart = 1
		getFlowerList()
	}

</script>

<style lang="scss" scoped>
	.flower{
		background:#fff;
		margin-bottom:100px;
	}
	.tab-wrap {
		margin: 20px 0px;
		display: flex;
		justify-content: space-between;
		padding-bottom: 20px;
		border-bottom: 1px solid #FE5BA3;

		.nav-box {
			display: flex;
			li {
				display: inline-block;
				font-size: 16px;
				font-weight: 400;
				color: #2D2C2C;
				width: 120px;
				height: 36px;
				line-height: 36px;
				text-align: center;
				cursor: pointer;
			}

			.selected-link {
				background: #FE5BA3;
				border-radius: 18px;
				color:#FFFFFF;
			}
		}
	}

	.flower-list {
		position: relative;
		height: calc(100vh + 50px);
	}

	.list-box {
		position: absolute;
		width: 420px;
		background: #FFFFFF;
		border-radius: 16px;
		box-shadow: 0px 0px 10px #ececec;
		padding: 10px;
		// break-inside: avoids;
		word-wrap: break-word;

		.info {
			font-size: 17px;
			font-weight: 400;
			color: #1D1D1D;
		}

		.info-img {
			display: inline-block;
			width: 125px;
			height: 85px;
			margin-right: 15px;
			margin-top: 15px;
			border-radius: 5px;
		}
	}
	.top-box {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	
		.name-wrap {
			display: flex;
			align-items: center;
	
			.name-box {
				margin-left: 10px;
	
				.name {
					font-size: 17px;
					font-weight: 500;
					color: #242424;
					font-weight: bold;
					margin-bottom: 10px;
				}
	
				.time {
					font-size: 14px;
					font-weight: 400;
					color: #585858;
				}
			}
		}
	
		.like-box {
			display: inline-flex;
			align-items: center;
			font-size: 15px;
			font-weight: 400;
			color: #727272;
	
			.icon-xihuan-shoucang-aixin {
				font-size: 30px;
				color: #727272;
			}
	
			.active {
				color: #FE5BA3;
			}
		}
	
	}
</style>
