<template>
	<el-dialog v-model="dialogVisible" :close-on-click-modal="false" title="抬头信息" draggable width="550px"
		@close="closeDialog">
		<el-form :model="invoiceInfoForm" ref="ruleFormRef" :rules="rules" label-width="100px">
			<el-form-item label="发票类型：" prop="invoiceType" class="u-m-b-15">
				<el-radio-group v-model="invoiceInfoForm.invoiceType">
					<el-radio :label="1">普票</el-radio>
					<el-radio :label="2">增值税专用票</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="抬头类型：" prop="titleType" class="u-m-b-15">
				<el-radio-group v-model="invoiceInfoForm.titleType">
					<el-radio :label="1">个人</el-radio>
					<el-radio :label="2">单位</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="发票材质：" prop="invoiceMode" class="u-m-b-15">
				<el-radio-group v-model="invoiceInfoForm.invoiceMode">
					<el-radio :label="1">电子版</el-radio>
					<!-- <el-radio :label="2">纸质版</el-radio> -->
				</el-radio-group>
			</el-form-item>
			<el-form-item label="发票抬头：" prop="invoiceTitle" class="u-m-b-15">
				<el-input type="text" v-model="invoiceInfoForm.invoiceTitle"></el-input>
			</el-form-item>
			<div v-if="invoiceInfoForm.titleType==2">
				<el-form-item label="单位税号：" prop="taxNum" class="u-m-b-15">
					<el-input type="text" v-model="invoiceInfoForm.taxNum"></el-input>
				</el-form-item>
			</div>
			<el-form-item label="邮箱：" prop="email" class="u-m-b-15">
				<el-input type="text" v-model="invoiceInfoForm.email"></el-input>
			</el-form-item>
			<el-form-item label="是否默认抬头：" prop="isDefaultTitle" class="u-m-b-15">
				<el-radio-group v-model="invoiceInfoForm.isDefaultTitle">
					<el-radio :label="0">否</el-radio>
					<el-radio :label="1">是</el-radio>
				</el-radio-group>
			</el-form-item>
			<div v-if="invoiceInfoForm.invoiceType==2"
				style="margin-left:10px;display:inline-flex;align-items: center;">
				<el-checkbox v-model="invoiceInfoForm.isAgree" size="large"></el-checkbox>
				<text class="agree-text">我已阅读并同意 <a @click="showVatInvoice" style="color:#078DDA;">《增值税专用发票抬头确认书》</a></text>
			</div>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="large" type="primary" @click="saveInfo(ruleFormRef)">提交申请</el-button>
			</span>
		</template>
		<vat-invoice ref="vatInvoiceRef"></vat-invoice>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted
	} from 'vue';
	import {
		userStore
	} from '@/store/user';
	import {
		memberInvoiceTitleSave
	} from '@/api/user';
	import {
		ElMessage
	} from 'element-plus';
	import vatInvoice from './vatInvoice.vue'
	const ruleFormRef = ref();
	const invoiceInfoForm = reactive({
		id: '',
		mode: 'add',
		invoiceType: 1,
		titleType: 1,
		invoiceTitle: '',
		invoiceMode: 1,
		email: '',
		taxNum: '',
		isDefaultTitle: 0
	});

	//表单验证
	const rules = reactive({
		invoiceTitle: [{
			required: true,
			message: '发票抬头不能为空',
			trigger: 'blur'
		}],
		taxNum: [{
				required: true,
				message: '税号不能为空',
				trigger: 'blur'
			},
			{
				pattern: /^[a-zA-Z0-9]{15}$|^[a-zA-Z0-9]{17}$|^[a-zA-Z0-9]{18}$|^[a-zA-Z0-9]{20}$/,
				message: '请输入正确的单位税号',
				trigger: 'blur'
			}
		],
		email: [{
				required: true,
				message: '邮箱不能为空',
				trigger: 'blur'
			},{
			pattern: /^[A-Za-z0-9\._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
			message: '请输入合法的邮箱',
			trigger: 'blur'
		}
		]
	});
	//增值税专用发票抬头确认书
	const vatInvoiceRef = ref(false);
	const showVatInvoice = () => {
		vatInvoiceRef.value.onChangeVatInvoiceVisible()
	};
	//保存信息
	const saveInfo = (form) => {
		if (!form) return
		if (invoiceInfoForm.invoiceType == 2 && !invoiceInfoForm.isAgree) {
			ElMessage({
				message: '请先阅读并勾选《增值税专用发票抬头确认书》',
				type: 'error',
				duration: 1000
			})
			return
		}
		form.validate((valid, fields) => {
			if (valid) {
				let res = {
					mode: invoiceInfoForm.mode,
					invoiceType: invoiceInfoForm.invoiceType,
					titleType: invoiceInfoForm.titleType,
					invoiceTitle: invoiceInfoForm.invoiceTitle,
					invoiceMode: invoiceInfoForm.invoiceMode,
					email: invoiceInfoForm.email,
					taxNum: invoiceInfoForm.taxNum,
				}
				memberInvoiceTitleSave(res).then(() => {
					ElMessage({
						message: '提交成功',
						type: 'success',
						duration: 1000
					})
					dialogVisible.value = false;
				})
			}
		})
	};

	//打开弹窗时
	const dialogVisible = ref(false);
	const onChangeAddTitleVisible = () => {
		invoiceInfoForm.invoiceTitle = userStore().memberInfo.memberName;
		dialogVisible.value = true;
	};

	defineExpose({
		onChangeAddTitleVisible
	});

	//关闭弹窗重置表单
	const closeDialog = () => {
		dialogVisible.value = false;
		ruleFormRef.value.resetFields();
	}
</script>

<style lang="scss" scoped>

</style>