<template>
  <div class="w cart" style="min-height: 860px">
    <!-- <div class="cart-top">
			<div class="cart-top__title">购物车信息</div>
			<div class="cart-top__left">
				<div style="color: #626262;">配送地址：</div>
				<el-select disabled style="width: 300px" v-model="address.id" placeholder="请选择地址">
    			<el-option v-for="(item, index) in address.list" :key="index" :label="item.label" :value="item.value" />
  			</el-select>
			</div>
		</div> -->
    <el-table
      :data="cartInfo.cartList"
      style="width: 100%; margin-top: 20px"
      border
      size="small"
      :row-class-name="tableRowClassName"
    >
      <el-table-column label="选择" width="40" align="center">
        <template #default="scope">
          <el-checkbox v-model="scope.row.checked" size="large" @change="onSelectProduct" v-if="!scope.row.isOverdue" />
          <el-text class="mx-1" type="info" v-else>已过期</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="ProductCode" label="编码" align="center" width="70" />
      <el-table-column label="图" align="center" width="27">
        <template #default="scope">
          <el-image :src="handlerImgUrl(scope.row.PicPath)" style="width: 27px; height: 36px"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="类型" align="center" width="40">
        <template #default="scope">{{
          scope.row.SaleType == 1 ? '现售' : scope.row.SaleType == 2 ? '拍售' : '预售'
        }}</template>
      </el-table-column>
      <el-table-column label="品名" align="center">
        <template #default="scope">{{ scope.row.Brand + scope.row.ProductName + scope.row.Grade }}</template>
      </el-table-column>
      <el-table-column prop="Color" label="颜色" align="center" width="60" />
      <el-table-column prop="Length" label="长度" align="center" width="60" />
      <el-table-column prop="PackCount" label="包装" align="center" width="60" />
      <el-table-column prop="ProductPrice" label="单价" align="center" width="50" />
      <el-table-column label="数量" align="center" width="100">
        <template #default="scope">
          <el-input-number
            style="width: 90px"
            size="small"
            v-model="scope.row.OrderCount"
            :min="1"
            @change="onStep(scope.row)"
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column prop="OrderMoney" label="金额" align="center" />
      <el-table-column prop="Commission" label="佣金" align="center" />
      <el-table-column label="活动" align="center">
        <template #default="scope">
          <div class="activity-item" v-for="(item, index) in scope.row.DiscountAndGiftQuantityList" :key="index">
            {{ ACTIVITY_TYPE[item.promotionType].label }}: {{ item.salesPromotionName }}
          </div>
          <div v-if="scope.row.DiscountAndGiftQuantityList.length == 0">暂无活动</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="60">
        <template #default="scope">
          <el-button text type="danger" @click="onDeleteCart(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="all-box">
      <div>
        <el-button size="small" @click="toggleSelection">{{
          cartInfo.cartList.length > 0 && cartInfo.cartList.length == selectProList.length ? '取消全选' : '全选'
        }}</el-button>
        <el-button size="small" text @click="onDeleteSelectProduct">删除选择的产品</el-button>
        <el-button size="small" text @click="onOverdueProduct" v-if="cartInfo.cartList.some(item => item.isOverdue)"
          >清空已过期产品</el-button
        >
      </div>
      <el-text type="danger" size="large" v-if="totalAmount.number > 0">你选择了{{ totalAmount.number }}件商品</el-text>
    </div>
    <div class="u-m-t-15">
      <div class="total-box">
        <div class="money-label">商品总金额：</div>
        <div class="money">￥{{ totalAmount.totalMoney.toFixed(2) }}</div>
      </div>
      <div class="total-box">
        <div class="money-label">预估佣金：</div>
        <div class="money">￥{{ totalAmount.totalCommission.toFixed(2) }}</div>
      </div>
      <div class="total-box">
        <div class="money-label">预估打包费：</div>
        <div class="money">￥{{ totalAmount.totalPackingFee.toFixed(2) }}</div>
      </div>
      <div class="total-box">
        <div class="money-label">预估运费：</div>
        <div class="money">￥{{ totalAmount.totalFreight.toFixed(2) }}</div>
      </div>
      <!-- <div class="total-box">
				<div class="money-label">折扣：</div>
				<div class="money">{{ totalAmount.discount }}</div>
			</div>
			<div class="total-box">
				<div class="money-label">赠送数量：</div>
				<div class="money">{{ totalAmount.giftQuantity }}</div>
			</div> -->
      <div class="total-box">
        <div class="money-label">实际应付款：</div>
        <div class="actual-pay money">￥{{ totalAmount.actualPay.toFixed(2) }}</div>
      </div>
      <div class="total-box">
        <el-button type="primary" @click="onSubmitOrder">提交订单</el-button>
      </div>
      <div class="total-box u-m-t-5">
        <el-text type="warning" size="large">从购物车提交的订单不可修改和取消</el-text>
      </div>
    </div>

    <!-- 会员充值 -->
    <member-recharge-dialog ref="rechargeRef"></member-recharge-dialog>
  </div>
</template>

<script setup>
import memberRechargeDialog from '@/components/recharge-modal/index.vue';
import { handlerImgUrl } from '@/common/index';
import { reactive, ref, onMounted, computed } from 'vue';
import { ElTable } from 'element-plus';
import { ElMessage, ElMessageBox } from 'element-plus';
import { cartStore } from '@/store/cart';
import { ACTIVITY_TYPE } from '@/enums/product';
import {
  deleteShopCart,
  saveShopCart,
  submitShopCart,
  deleteOverdueProduct,
  getCartDeliveryFeeAndPackingFee
} from '@/api/cart';
import { memberAddressList } from '@/api/user';
import { startLoading } from '@/common';

const cartInfo = cartStore();

onMounted(() => {
  getMemberAddressList();
  initData();
});

// 将过期置为灰色
const tableRowClassName = ({ row }) => (row.isOverdue ? 'grey-row' : '');

/**
 * 重置数据
 * @param {无}
 */
const initData = () => {
  selectProList.value = []; // 清空选中的产品列表
  computeData();
  cartInfo.getCartList(); // 刷新购物车列表
};

/**
 * 取地址列表
 * @param {无}
 */
const address = reactive({
  id: '',
  list: []
});
const getMemberAddressList = async () => {
  const {
    data: { records }
  } = await memberAddressList();
  address.list = records.map(item => {
    return { label: `${item.Province}${item.City}${item.Area}${item.Street}${item.DetailAddr}`, value: item.Id };
  });
  address.id = records.find(item => item.IsDefault == 1).Id;
};

// 选中的产品列表
const selectProList = ref([]);
const totalAmount = reactive({
  totalMoney: 0,
  totalCommission: 0,
  totalPackingFee: 0,
  totalFreight: 0,
  discount: 0,
  giftQuantity: 0,
  actualPay: 0,
  number: 0
});
/**
 * 计算各种金额总和
 * @param {无}
 */
const computeData = async () => {
  // 先给为0，再计算
  Object.keys(totalAmount).forEach(key => (totalAmount[key] = 0));
  for (let item of selectProList.value) {
    totalAmount.totalMoney += item.OrderMoney; // 商品总金额
    totalAmount.totalCommission += item.Commission; // 预估佣金
    totalAmount.number += item.OrderCount;
  }
  if (selectProList.value.length > 0) {
    const params = { ids: selectProList.value.map(item => item.Id).join(';') };
    const {
      data: {
        data: { deliveryFee, packingFee }
      }
    } = await getCartDeliveryFeeAndPackingFee(params);
    totalAmount.totalPackingFee = packingFee; // 预估打包费
    totalAmount.totalFreight = deliveryFee; // 预估运费
  }
  const { totalMoney, totalCommission, totalPackingFee, totalFreight } = totalAmount;
  totalAmount.actualPay = totalMoney + totalCommission + totalPackingFee + totalFreight; // 实际应付款
};

/**
 * 监听保存数量
 * @param {object} event
 */
const onStep = async event => {
  if (event.OrderCount >= event.TimelyCount) {
    ElMessage.warning('订购量过大，库存不足');
    return;
  }
  const params = {
    id: event.Id,
    saleType: event.SaleType,
    orderCount: event.OrderCount
  };
  const { code } = await saveShopCart(params);
  if (code == 0) {
    await cartInfo.getCartList();
    // 重新选中
    cartInfo.cartList.map(item => {
      item.checked = selectProList.value.some(subItem => subItem.Id == item.Id);
    });
    selectProList.value = cartInfo.cartList.filter(item => item.checked);
    computeData();
  }
  event.OrderCount = 1;
};

/**
 * 选择产品
 * @param {无}
 */
const onSelectProduct = () => {
  selectProList.value = cartInfo.cartList.filter(item => !item.isOverdue && item.checked);
  computeData();
};
/**
 * 全选产品
 * @param {无}
 */
const toggleSelection = () => {
  cartInfo.cartList.map(item => (item.checked = !(cartInfo.cartList.length == selectProList.value.length)));
  selectProList.value = cartInfo.cartList.filter(item => !item.isOverdue && item.checked);
  computeData();
};

/**
 * 清空产品
 * @param {object} event
 */
const params = reactive({ delType: 1, ids: '' });
const onDeleteCart = event => {
  ElMessageBox.alert('确定清空该产品吗？', '提示', {
    type: 'warning',
    confirmButtonText: '确定'
  }).then(async () => {
    params.ids = event.Id;
    await deleteShopCart(params);
    ElMessage.success('清空成功');
    initData();
  });
};

/**
 * 清空选择的产品
 * @param {无}
 */
const onDeleteSelectProduct = () => {
  if (selectProList.value.length == 0) {
    ElMessage.warning('请选择产品');
    return;
  }
  ElMessageBox.alert('确定清空该产品吗？', '提示', {
    type: 'warning',
    confirmButtonText: '确定'
  }).then(async () => {
    params.ids = selectProList.value.map(item => item.Id).join(';');
    await deleteShopCart(params);
    ElMessage.success('清空成功');
  });
};

/**
 * 清空已过期产品
 * @param {无}
 */
const onOverdueProduct = () => {
  ElMessageBox.alert('确定清空已过期产品吗？', '提示', {
    type: 'warning',
    confirmButtonText: '确定'
  }).then(async () => {
    await deleteOverdueProduct();
    cartInfo.cartList = cartInfo.cartList.filter(item => !item.isOverdue);
    cartInfo.total = cartInfo.cartList.length;
    ElMessage.success('清空成功');
  });
};

/**
 * 提交订单
 * @param {无}
 */
const rechargeRef = ref();
const onSubmitOrder = async () => {
  if (selectProList.value.length == 0) return ElMessage.warning('请选择产品');
  startLoading(
    async () => {
      params.ids = selectProList.value.map(item => item.Id).join(';');
      const { code } = await submitShopCart(params);
      if (code == 0) {
        ElMessage.success('提交成功');
        initData();
      }
      if (code == 10) {
        ElMessage.warning('余额不足，请充值');
        rechargeRef.value.onChangeVisible();
      }
    },
    { text: '正在提交中，请稍后...' }
  );
};
</script>

<style lang="scss">
.cart {
  .el-checkbox {
    .el-checkbox__input .el-checkbox__inner {
      width: 20px !important;
      height: 20px !important;
    }

    .el-checkbox--small .el-checkbox__inner {
      &::after {
        width: 10px !important;
        height: 10px !important;
      }
    }
  }

  .el-table .grey-row {
    --el-table-tr-bg-color: #eeeeee;
  }
}
</style>
<style lang="scss" scoped>
.cart-top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__title {
    font-size: 18px;
    font-weight: bold;
    color: #3a3a3a;
    line-height: 55px;
  }

  &__left {
    display: flex;
    align-items: center;
  }
}

.activity-item {
  color: #fd010d;
  padding: 2px 5px;
  margin-bottom: 10px;
  background-color: #ffe6e7;
  border-radius: 2px;

  &:last-child {
    margin-bottom: 0;
  }
}

.all-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 400;
  color: #626262;
  background-color: #f8f8f8;
  height: 60px;
  line-height: 60px;
  padding: 0 5px;
}

.total-box {
  display: flex;
  justify-content: flex-end;

  .money-label {
    font-size: 18px;
    font-weight: 400;
    color: #696969;
    line-height: 42px;
  }

  .money {
    width: 90px;
    font-size: 18px;
    font-weight: 400;
    color: #3e3e3e;
    line-height: 42px;
    text-align: right;
  }

  .actual-pay {
    color: #ff0000;
    font-size: 22px;
    font-weight: bold;
  }
}
</style>
