<template>
  <view class="search-box">
    <el-autocomplete
        style="width: 200px"
        class="u-m-r-15"
        v-model="state.keywords"
        @input="updateKeywords"
        clearable
        placeholder="品名、品牌、颜色"
        :fetch-suggestions="querySearch"
        @keydown="handleKeyMap"
    >
      <template #suffix>
        <i class="iconfont icon-sousuo" @click="handleSearch"></i>
      </template>
    </el-autocomplete>
    <el-radio-group :model-value="sale_type" class="u-m-r-15" @change="handleSaleType">
      <el-radio-button :label="7">{{
          '晚场' + '(' + moment(getAfterNoonSaleDate()).format('MM-DD') + ')'
        }}
      </el-radio-button>
      <el-radio-button :label="1">{{ '现售' + '(' + moment().format('MM-DD') + ')' }}</el-radio-button>
      <el-radio-button :label="2">{{ '拍售' + '(' + moment().format('MM-DD') + ')' }}</el-radio-button>
      <el-radio-button :label="3" v-if="state.tommorow_total > 0">{{
          `明天（${moment().add(1, 'day').format('MM-DD')}）`
        }}
      </el-radio-button>
      <!-- <el-radio-button :label="4" v-if="productCount.afterTomorrowCount > 0">{{ getTagDate(4) }}</el-radio-button> -->
      <!-- <el-radio-button :label="5" v-if="productCount.afterThreeDaysCount > 0">{{ getTagDate(5) }}</el-radio-button> -->
      <!-- <el-radio-button :label="6" v-if="state.params.saleType != 6">预售</el-radio-button> -->
    </el-radio-group>
    <el-date-picker
        type="date"
        :model-value="sale_date"
        size="default"
        :disabled-date="state.disable_date"
        placeholder="选择发货日期"
        :clearable="false"
        format="YYYY-MM-DD"
        class="u-m-r-15"
        style="width: 100px"
        @change="$emit('update:sale_date', $event.target.value)"
        v-if="sale_type == 6"
    ></el-date-picker>
    <el-radio-group
        :model-value="brand_type"
        class="u-m-r-15"
        @change="$emit('update:brand_type', $event.target.value)"
    >
      <!-- <el-radio-button :label="2" >品牌馆</el-radio-button> -->
      <!-- <el-radio-button :label="0">主页</el-radio-button> -->
    </el-radio-group>
    <el-checkbox-group class="u-m-r-15" v-model="state.grade_select_list" @change="handleGrade">
      <el-checkbox-button v-for="item in state.grade_list" :key="item" :label="item">{{ item }}</el-checkbox-button>
    </el-checkbox-group>
    <el-radio-group style="padding: 5px 0">
      <el-radio-button label="颜色" @click="handleOpenColor"/>
      <el-radio-button label="品牌" @click="handleOpenBrand"/>
    </el-radio-group>

    <!-- 品牌选择弹窗 -->
    <brandModal
        ref="brandModalRef"
        v-model:brandDialogVisible="state.brandModalVisible"
        :sale_type="sale_type"
        :color="color"
        :sale_date="state.sale_date"
        :grade="grade"
        :main_category="main_category"
        :sub_category="sub_category"
        :product_name="state.keywords"
        :brand="brand"
        @update_brand="handleUpdateBrand"
        @update_brand_code="handUpdateBrandCode"
    >
    </brandModal>

    <!-- 颜色选择弹窗 -->
    <colorModal
        ref="colorModalRef"
        v-model:colorDialogVisible="state.colorModalVisible"
        :sale_type="sale_type"
        :sale_date="state.sale_date"
        :color="color"
        :grade="grade"
        :keywords="state.keywords"
        :brand_code="state.brand_code"
        @update_color="handleUpdateColor"
    >
    </colorModal>
  </view>
</template>

<script setup>
import {SALE_TYPE} from '@/common/const';
import moment from 'moment';
import {onMounted, reactive, ref, watch} from 'vue';
import brandModal from '../brand-modal/index.vue';
import colorModal from '../color-modal/index.vue';
import {querySearch} from './index.js';
import {withDefaultsParams, getAfterNoonSaleDate} from '@/common';
import {productSaleSumListBySaleType} from '@/api/productSale';

const props = defineProps({
  sale_type: {
    type: [String, Number],
    default: SALE_TYPE.TODAY
  },
  color: {
    type: String,
    default: ''
  },
  brand: {
    type: String,
    default: ''
  },
  grade: {
    type: String,
    default: ''
  },
  brand_type: {
    type: Number,
    default: 0
  },
  main_category: {
    type: String,
    default: ''
  },
  sub_category: {
    type: String,
    default: ''
  }
});

const emit = defineEmits([
  'update:keywords',
  'update:sale_type',
  'update:brand_type',
  'update:grade',
  'update:color',
  'update:brand',
  'update:brand_code',
  'update:sale_date'
]);

const state = reactive({
  keywords: '',
  grade_select_list: props.grade.split(','),
  brand_code: '',
  sale_date: moment().format('YYYY-MM-DD'),
  brand_type: '',
  disable_date: true,
  grade_list: ['A', 'B', 'C', 'D', 'E'],
  brandModalVisible: false,
  colorModalVisible: false,
  tommorow_total: 0
});

const brandModalRef = ref();
const colorModalRef = ref();

onMounted(() => {
  getProductAggregationList();
});

/**
 * 获取产品聚合列表参数
 */
const productAggregationListParams = withDefaultsParams({
  keyword: '',
  productName: '',
  isNew: 0,
  brandType: 0,
  brand: '',
  color: '',
  grade: '',
  tagId: '',
  mainCategory: '',
  subCategory: '',
  isMyLove: 0,
  sort: 0,
  priceSort: 0,
  timelyCountSort: 0,
  saleType: SALE_TYPE.TOMMOROW,
  limit: {
    enable: true,
    conditions: {
      pageLength: 5,
      pageStart: 1
    }
  }
});

/**
 * 获取产品聚合列表
 */
const getProductAggregationList = productAggregationListParams(async query => {
  const {
    code,
    data: {filterAmount, records}
  } = await productSaleSumListBySaleType(query);
  if (code == 0) {
    state.tommorow_total = filterAmount;
  }
});

function handleKeyMap(event) {
  if (event.keyCode == 13) {
    emit('update:keywords', state.keywords);
  }
}

function handleSearch() {
  emit('update:keywords', state.keywords);
}

function handleSelectKeywords(event) {
  emit('update:keywords', event);
}

function updateKeywords(event) {
  console.log(event);
  if (event == '') {
    state.keywords = event;
    emit('update:keywords', event);
  }
}

/**
 * 选择发货时间
 */
function handleChangeDate() {
}

/**
 * 切换销售类型
 */
function handleSaleType(event) {
  if (event == SALE_TYPE.TODAY || event == SALE_TYPE.BID) {
    state.sale_date = moment().format('YYYY-MM-DD');
  } else if (event == SALE_TYPE.TOMMOROW) {
    state.sale_date = moment().add(1, 'day').format('YYYY-MM-DD');
  } else if (event == SALE_TYPE.AFTERNOON) {
    state.sale_date = getAfterNoonSaleDate();
  }

  emit('update:sale_type', event);
  emit('update:sale_date', state.sale_date);
}

/**
 * 选择等级
 */
function handleGrade(event) {
  emit('update:grade', event.filter(item => item !== '').join(','));
}

/**
 * 选择颜色
 */
function handleUpdateColor(event) {
  emit('update:color', event);
}

/**
 * 打开颜色选择窗口
 */
function handleOpenColor() {
  state.colorModalVisible = true;
}

function handleOpenBrand() {
  state.brandModalVisible = true;
}

/**
 * 选择品牌
 */
function handleUpdateBrand(event) {
  emit('update:brand', event);
}

function handUpdateBrandCode(event) {
  state.brand_code = event;
  emit('update:brand_code', event);
}
</script>

<style lang="scss">
@import './index.scss';
</style>
