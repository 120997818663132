<template>
  <div class="product-category">
    <div class="category-title">产品分类</div>
    <!-- 类型分类 -->
    <div class="category-box">
      <a
        class="category"
        v-for="(item, index) in state.label_list"
        :class="{
          'category--active': main_category == '' ? label == item.id : false
        }"
        :key="index"
        @click="handleLabel(item.id)"
        >{{ item.name }}</a
      >
      <!-- 大分类 -->
      <a
        class="category"
        v-for="(item, idx) in state.main_category_list"
        :class="{ 'category--active': item.main_category == main_category }"
        :key="idx"
        @click="handleMainCategory(item.main_category)"
        >{{ item.main_category }}</a
      >
    </div>
    <!-- 小分类 -->
    <div style="min-height: 150px" class="category-box" v-if="state.sub_category_list.length > 0">
      <a
        class="category"
        v-for="(item, index) in state.sub_category_list"
        :key="index"
        :class="{ 'category--active': item.sub_category == sub_category }"
        @click="handleSubCategory(item.sub_category)"
        >{{ item.sub_category }}</a
      >
    </div>
    <!-- 功能按钮 -->
    <leftFuButton></leftFuButton>
  </div>
</template>

<script setup>
import { onMounted, reactive, watch } from 'vue';
import leftFuButton from './components/buttons/index.vue';
import {
  findPresaleMainCategory,
  findPresaleSubCategory,
  getProductLabelList,
  productMainCategoryList,
  productSubCategoryList
} from '@/api/productSale';
import { isPresale, mappingLabelToShow, mappingMainListToShow, mappintSubListToShow } from './index.js';
import { withDefaultsParams } from '@/common/index.js';
import { SALE_TYPE } from '@/common/const.js';
import moment from 'moment';

const props = defineProps({
  sale_date: {
    type: String,
    default: moment().format('YYYY-MM-DD')
  },
  sale_type: {
    type: Number,
    default: SALE_TYPE.TODAY
  },
  keywords: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: ''
  },
  grade: {
    type: String,
    default: 'A'
  },
  brand_code: {
    type: String,
    default: ''
  },
  main_category: {
    type: String,
    default: ''
  },
  sub_category: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  }
});

const state = reactive({
  // 产品大类列表
  main_category_list: [],
  // 产品小类列表
  sub_category_list: [],
  // 活动标签列表
  label_list: []
});

const emit = defineEmits(['update:main_category', 'update:label', 'update:sub_category']);

onMounted(() => {
  getLabelList();
  isPresale(props.sale_type) ? getMainCategoryListPresale() : getMainCategoryList();
});

watch(
  () => props.main_category,
  (newValue, oldValue) => {
    console.log('大类小类属性变化');
    if (props.main_category == '') {
      state.sub_category_list = [];
    }
    refreshList();
  }
);

watch(
  () => props.sale_type,
  (newValue, oldValue) => {
    console.log('大类小类销售类型变化');
    if (props.main_category == '') {
      state.sub_category_list = [];
    }

    refreshList();
  }
);

function refreshList() {
  getLabelList();
  isPresale(props.sale_type) ? getMainCategoryListPresale() : getMainCategoryList();
}

/**
 * 点击标签
 */
function handleLabel(item) {
  console.log('点击标签', item);
  emit('update:label', item);
  emit('update:main_category', '');
  emit('update:sub_category', '');
}

/**
 * 点击大类
 */
function handleSubCategory(sub_category) {
  console.log(sub_category);
  emit('update:sub_category', sub_category);
}

/**
 * 点击小类
 */
function handleMainCategory(main_category) {
  console.log('点击大类', props.sale_type, main_category);
  isPresale(props.sale_type)
    ? getSubCategoryListPresale({
        main_category
      })
    : getSubCategoryList({
        mainCategory: main_category
      });
  emit('update:main_category', main_category);
  emit('update:sub_category', '');
  emit('update:label', '');
}

/**
 * 获取大类列表参数
 */
const mclp = withDefaultsParams({
  saleType: props.sale_type
  // saleDate: props.sale_date
});
const getMainCategoryList = mclp(async params => {
  try {
    const {
      data: { records }
    } = await productMainCategoryList(params);
    state.main_category_list = mappingMainListToShow(records);
  } catch (err) {
    console.log(err);
  }
});
/**
 * 获取售产品大类列表参数
 */
const mclpp = withDefaultsParams({
  sale_date: props.sale_date,
  keywords: props.keywords,
  brand_code: props.brand_code,
  color: props.color,
  grade: props.grade
});
/**
 * 获取预售产品大类列表  * 刷新标签信息
 */
const getMainCategoryListPresale = mclpp(async params => {
  const { data } = await findPresaleMainCategory(params);
  console.log('获取预售大类列表', data);
  state.main_category_list = mappingMainListToShow(data);
});

/**
 * 获取小类列表参数
 */
const sclp = withDefaultsParams({
  saleDate: props.sale_date,
  saleType: props.sale_type,
  mainCategory: ''
});

/**
* 获取小类列表
} */
const getSubCategoryList = sclp(async params => {
  try {
    const {
      data: { records }
    } = await productSubCategoryList(params);
    state.sub_category_list = mappintSubListToShow(records);
  } catch (err) {
    console.log('error', err);
  }
});

/**
 * 获取预售小类列表参数
 */
const with_sub_category_default_params = withDefaultsParams({
  sale_date: props.sale_date,
  main_category: '',
  keywords: props.keywords,
  brand_code: props.brand_code,
  color: props.color,
  grade: props.grade
});
/**
 * 获取预售产品小类列表
 */
const getSubCategoryListPresale = with_sub_category_default_params(async params => {
  const { data } = await findPresaleSubCategory(params);
  console.log('预售小类', data);
  state.sub_category_list = mappintSubListToShow(data);
});

/**
 * 获取标签信息参数
 */
const labelListParams = withDefaultsParams({});

const getLabelList = labelListParams(async params => {
  try {
    const {
      data: { records }
    } = await getProductLabelList();
    state.label_list = records.filter(item => item.ProductCount > 0);
    state.label_list = mappingLabelToShow(state.label_list);
  } catch (error) {
    console.log(error);
  }
});

/**
 * 刷新标签信息
 */
function onRefreshLabel() {}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
