import request from "@/utils/request";

// 取首页广告图列表
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-70838362
export const getAdList = (data = {}) => {
  return request.post("/api/advert/advertListWeb", data);
};


export const getServerTime = (data = {})=> {
  return request.post("api/serverTimeTamp/timeTamp", data);
}
