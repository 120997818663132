<template>
	<div class="w">
		<!-- 轮播图 -->
		<el-carousel height="400px" arrow="always" :interval="3000" class="carousel-box">
			<el-carousel-item v-for="item in adList" :key="item">
				<router-link to="/index/orderProduct">
					<el-image class="adImg" :src="item.PicUrl"></el-image>
				</router-link>
			</el-carousel-item>
		</el-carousel>

		<!-- 分类 -->
		<div class="classfy-box">
			<div class="classfy-bg" v-for="(item, index) in labelList" :key="index" @click="router.push({ path: '/index/orderProduct', query: { tagId: item.Id } })">
				<img class="classfy-img" :src="item.Icon">
				<div class="classfy">{{ item.Name }}</div>
			</div>
		</div>

		<!-- 分类列表 -->
		<div class="classfy-list-wrap">
			<div v-for="(item, index) in labelList" :key="index">
				<div class="classfy-list-box" v-if="item.Id">
					<div class="classfy-title-box">
						<div class="classfy-title">{{ item.Name }}</div>
						<div class="more" @click="router.push({ path: '/index/orderProduct', query: { tagId: item.Id } })">
							更多<i class="iconfont icon-xiangyou-yuan"></i>
						</div>
					</div>
					<div class="classfy-list">
						<div class="list-item" v-for="(subItem, subIndex) in item.list" :key="subIndex" @click="router.push({ path: '/index/orderProduct', query: { ...subItem } })">
							<el-image class="proImg" :src="subItem.PicPath"></el-image>
							<div class="proInfo">
								<div class="proName">{{ subItem.Name }}</div>
								<div class="proPrice">单价：{{ subItem.Price + '元/' + subItem.Unit }}</div>
								<div class="soldAmount">已售：{{ subItem.SaleCount }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script setup>
	import { ref, reactive, onMounted } from 'vue'
	import { useRouter } from 'vue-router'
	import {
		getAdList,
	} from '@/api/home'
	import {
		getProductLabelList,
		productSaleListBySaleType
	} from '@/api/productSale'
	import { handlerImgUrl } from '@/common/index'

	const router = useRouter()

	onMounted(async () => {
		await getBannerList()
		await initData()
	})

	/**
	 * 取广告位列表
	 * @param {无}
	 */
	const adList = ref([]);
	const labelList = ref([{ Icon: require('@/assets/image/typeBg1.png'), Name: '全部分类' }]);
	const getBannerList = async () => {
		const { data: { records } } = await getAdList()
		adList.value = records.filter(e => { return e.Location == '首页';});
		if(records!=null){
			let catePics=records.filter(e => { return e.Location == '全部分类图片';});
			labelList.value = [{ Icon: catePics[0].PicUrl, Name: '全部分类' }];
		}
	}

	/**
	 * 取产品标签列表
	 * @param {无}
	 */
	const params = {
		saleType: 1,
		limit: { enable: true, conditions: { pageLength: 5, pageStart: 1 } }
	}
	
	const initData = async () => {
		const { data: { records } } = await getProductLabelList()
		const arr = records.filter(item => item.ProductCount > 0) // 代表有在售产品
		// 根据标签id取现售产品列表
		for (let item of arr) {
			const result = await productSaleListBySaleType({ ...params, tagId: item.Id })
			item.list = result.data.records.map(subItem => {
				return { ...subItem, PicPath: handlerImgUrl(subItem.PicPath) }
			})
		}
		labelList.value = [...labelList.value, ...arr]
	}
</script>

<style lang="scss" scoped>
	@import '@/assets/style/home.scss';
</style>