<template>
	<el-dialog v-model="dialogVisible" :close-on-click-modal="false" title="申请售后" draggable width="780px"
		@close="closeDialog">
		<el-form :model="complaintForm" ref="complaintFormRef" label-width="100px">
			<div class="u-flex">
				<div class="u-label">订单编号：</div>
				<div style="color:#333333;">{{complaintForm.orderNo}}</div>
			</div>
			<div class="u-flex u-m-t-15">
				<div class="u-label">售后产品：</div>
				<div style="color:#333333;">{{complaintForm.brand+complaintForm.productName+complaintForm.grade}}</div>
				<div class="u-label">有效数量(扎)：</div>
				<div style="color:#333333;">{{complaintForm.effectiveTotalCount}}</div>
				<div class="u-label">购买单价：</div>
				<div style="color:#333333;">{{complaintForm.productPrice.toFixed(2)}}</div>
			</div>
			<div class="u-flex u-m-t-15">
				<el-form-item label="申请原因:" prop="complainReason" required :show-message="false">
					<el-select v-model="complaintForm.complainReason" filterable placeholder="请选择申请原因" style="width:250px;">
						<el-option v-for="item in reasonOptions" :key="item.value" :label="item.ParaName"
							:value="item.ParaName" />
					</el-select>
				</el-form-item>
				<el-form-item label="申请数量(扎):" class="myMoney" prop="complainQuanlity" required
					:show-message="false">
					<el-input-number v-model="complaintForm.complainQuanlity"  :controls="false"
						style="width:250px;"></el-input-number>
				</el-form-item>
			</div>
			<el-form-item label="投诉备注:" prop="complainRemark" required :show-message="false">
				<el-input v-model="complaintForm.complainRemark" type="textarea" :autosize="{ minRows: 4 }" clearable
					placeholder="请填写投诉备注" />
			</el-form-item>
			<el-form-item label="上传图片" class="u-m-t-15" prop="imgs" required :show-message="false">
				<div class="img-list" v-if="complaintForm.fileList.length > 0">
					<div class="img-item" v-for="(item, index) in complaintForm.fileList" :key="index"
						@click="handlePictureCardPreview(item.url)">
						<img :src="item.url">
						<div class="icon-item" @click.stop="handleRemove(index)">
							<el-icon :size="20">
								<CircleCloseFilled color="#fa3534" />
							</el-icon>
						</div>
					</div>
				</div>
				<el-upload v-if="complaintForm.fileList.length <= 9" action="https://up-z2.qiniup.com/"
					:data="uploadParams" list-type="picture-card" :format="['jpg','jpeg','png']" :show-file-list="false"
					:limit="9" :multiple="true" :on-success="onSuccessImg" :before-upload="beforeUpload">
					<el-icon>
						<Plus />
					</el-icon>
				</el-upload>
				<el-dialog v-model="dialogVisibleImg" width="630px" title="查看图片">
					<div style="">
						<img :src="dialogImageUrl" alt="Preview Image" style="max-width:600px;border:1px solid red;" />
					</div>
				</el-dialog>
			</el-form-item>
		</el-form>
		<div class="tips">
			<div class="u-title">图片要求说明:</div>
			<div class="u-font">1、请按下方示例图片分别上传产品编码、产品全景图、产品细节图,缺一不可;</div>
			<div class="u-font">2、仅需拍产品外包装编码的照片过来;</div>
			<div class="u-font">3、要把外包装拆开平铺能看出的问题，不接受视频投诉，麻烦拍照片;</div>
			<div class="u-font">4、照片要清晰的体现投诉的数量，看得出多少数量损坏这边处理多少，到货少货需要拍全部到货货品全家福;</div>
			<div class="u-font">5、级别不符需要用尺子量着对比拍照，打理过的货品不接受投诉，请保持原貌。</div>
			<div class="u-title u-m-t-15">示例图片（点击可放大查看）:</div>
			<div>
				<el-image style="width:100px;height:100px;margin-right: 15px;"
					:src="require('@/assets/image/proCode.jpeg')" :zoom-tate="1.2"
					:preview-src-list="[require('@/assets/image/proCode.jpeg')]" fit="cover"></el-image>
				<el-image style="width:100px;height:100px;margin-right: 15px;"
					:src="require('@/assets/image/proAll.jpeg')" :zoom-tate="1.2"
					:preview-src-list="[require('@/assets/image/proAll.jpeg')]" fit="cover"></el-image>
				<el-image style="width:100px;height:100px;margin-right: 15px;"
					:src="require('@/assets/image/proComplaint.jpeg')" :zoom-tate="1.2"
					:preview-src-list="[require('@/assets/image/proComplaint.jpeg')]" fit="cover"></el-image>
			</div>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button size="large" type="primary" @click="submitComplaintForm(complaintFormRef)"
					v-if="!complaintForm.orderComplainId">提交售后申请</el-button>
				<el-button size="large" type="primary" @click="updateComplaintForm(complaintFormRef)"
					v-if="complaintForm.orderComplainId">修改售后</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		reactive,
		ref
	} from 'vue';
	import {
		Plus,
		CircleCloseFilled
	} from '@element-plus/icons-vue'
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'
	import {
		handlerImgUrl
	} from "@/common/index";
	import {
		parametersComplainReasonList,
		getQiNiuYunToken,
		orderComplainSave,
		webOrderComplainInfo,
		orderComplainUpdate
	} from '@/api/user';
	const emit = defineEmits(['update'])

	// 获取表单对象
	const complaintFormRef = ref();
	const complaintForm = reactive({
		id: '',
		orderNo: '',
		brand: '',
		productName: '',
		grade: '',
		productPrice: 0,
		effectiveTotalCount: 0,
		complainReason: '',
		complainQuanlity: 0,
		complainRemark: '',
		imgs: '',
		fileList: [],
		orderComplainId: '',
    isUploadSuccess: false,
	});
	/**
	 * 申请售后原因列表
	 * @param {无}
	 */
	const reasonOptions = ref([]);
	const getComplainReasonList = () => {
		return parametersComplainReasonList({}).then(res => {
			reasonOptions.value = res.data.records;
		})
	}

	/**
	 * 上传图片
	 */
	const uploadParams = reactive({
		token: '',
		key: '',
	})
	const beforeUpload = async (request) => {
		// **注意这里的参数必须通过属性赋值，如果直接修改整个uploadParams是无效的。**
		// 上传之前设置上传参数,
		let filename = Date.now() + Math.random().toString(36).slice(2) + ".png";
		const {
			data: {
				token
			}
		} = await getQiNiuYunToken()
		uploadParams.token = token
		uploadParams.key = filename
	}
	// 删除图片
	const handleRemove = (index) => {
		complaintForm.fileList.splice(index, 1)
	}
	// 预览图片
	const dialogVisibleImg = ref(false)
	const dialogImageUrl = ref('')
	const handlePictureCardPreview = (event) => {
		dialogImageUrl.value = event
		dialogVisibleImg.value = true
	}

	const onSuccessImg = (uploadFile) => {
		complaintForm.fileList.push({
			name: uploadFile.key,
			url: handlerImgUrl(uploadFile.key),
		});
    complaintForm.isUploadSuccess = true;
    console.log(complaintForm.isUploadSuccess,'我在这')
	}


	//验证
	const validateForm = () => {
		if (!complaintForm.complainReason) {
			ElMessageBox({
				title: '温馨提示',
				message: '请选择申请原因'
			})
			return false;
		}
		if (complaintForm.complainQuanlity <= 0) {
			ElMessageBox({
				title: '温馨提示',
				message: '请输入申请数量'
			})
			return false;
		}
		if (complaintForm.complainQuanlity > complaintForm.effectiveTotalCount) {
			ElMessageBox({
				title: '温馨提示',
				message: '申请数量不能大于购买时的有效数量'
			})
			return false;
		}
		if (!complaintForm.complainRemark) {
			ElMessageBox({
				title: '温馨提示',
				message: '请填写投诉备注'
			})
			return false;
		}
		if (complaintForm.fileList.length < 3) {
			ElMessageBox({
				title: '温馨提示',
				message: '请按下方示例图片分别上传产品编码、产品全景图、产品细节图'
			})
			return false;
		}
    if(!complaintForm.isUploadSuccess){
      ElMessageBox({
        title: '温馨提示',
        message: '图片还未上传完成，请稍等'
      })
      return false;
    }
		return true;
	}

	// 保存
	const submitComplaintForm = async () => {
		if (validateForm() == false) {
			return
		};
		if (complaintForm.fileList.length > 0) {
			complaintForm.imgs = complaintForm.fileList.map(item => item.name).join(',')
		}
		let res = {
			orderId: complaintForm.id,
			complainReason: complaintForm.complainReason,
			complainQuanlity: complaintForm.complainQuanlity,
			complainRemark: complaintForm.complainRemark,
			imgs: complaintForm.imgs,
		}
		await orderComplainSave(res).then(() => {
			ElMessage({
				message: '提交成功',
				type: 'success',
				duration: 1000
			})
			dialogVisible.value = false
			emit('update', true)
		})
	}

	/**
	 * 获取商品评价信息
	 * @param {无}
	 */
	const getOrderComplainInfo = () => {
		return webOrderComplainInfo({
			id: complaintForm.orderComplainId
		}).then(res => {
			let orderComplainInfo = res.data.orderComplainInfo;
			complaintForm.orderComplainId = orderComplainInfo.id;
			complaintForm.orderNo = orderComplainInfo.orderNum;
			complaintForm.complainReason = orderComplainInfo.complainReason;
			complaintForm.complainQuanlity = orderComplainInfo.complainQuanlity;
			complaintForm.complainRemark = orderComplainInfo.complainRemark;
			complaintForm.imgs = orderComplainInfo.imgs;
			let files = complaintForm.imgs.split(",");
			let tmpFilesList = [];
			files.forEach((e) => {
				if (e) {
					tmpFilesList.push({
						name: e,
						url: handlerImgUrl(e)
					})
				}
			});
			complaintForm.fileList = tmpFilesList;
		})
	}
	// 修改售后
	const updateComplaintForm = async () => {
		if (validateForm() == false) {
			return
		};
		if (complaintForm.fileList.length > 0) {
			complaintForm.imgs = complaintForm.fileList.map(item => item.name).join(',')
		}
		let res = {
			orderComplainId: complaintForm.orderComplainId,
			complainReason: complaintForm.complainReason,
			complainQuanlity: complaintForm.complainQuanlity,
			complainRemark: complaintForm.complainRemark,
			imgs: complaintForm.imgs,
		}
		await orderComplainUpdate(res).then(() => {
			ElMessage({
				message: '提交成功',
				type: 'success',
				duration: 1000
			})
			dialogVisible.value = false
			emit('update', true)
		})
	}
	/**
	 * 弹窗显示
	 * @param {无}
	 */
	const dialogVisible = ref(false);
	const onChangeVisible = (event) => {
		complaintForm.id = event.Id
		complaintForm.orderNo = event.OrderNo
		complaintForm.brand = event.Brand
		complaintForm.productName = event.ProductName
		complaintForm.grade = event.Grade
		complaintForm.effectiveTotalCount = event.EffectiveTotalCount
		complaintForm.productPrice = event.ProductPrice
		complaintForm.orderComplainId = event.OrderComplainId
		getComplainReasonList();
		if (complaintForm.orderComplainId) {
			getOrderComplainInfo();
		}
		dialogVisible.value = true;
	};
	defineExpose({
		onChangeVisible
	});

	//关闭弹窗重置表单
	const closeDialog = () => {
		dialogVisible.value = false;
		complaintFormRef.value.resetFields();
		complaintForm.fileList = [];
	}

</script>

<style lang="scss">
	.myMoney {
		.el-input-number .el-input__inner {
			text-align: left !important;
		}
	}

	.my-label {
		display: flex;
		width: 150px;
	}
</style>
<style lang="scss" scoped>
	.img-list {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		.img-item {
			position: relative;
			width: 145px;
			height: 145px;
			border-radius: 10px;
			margin: 0 10px 10px 0;
			border: 1px solid #cdd0d6;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.icon-item {
			position: absolute;
			top: 4px;
			right: 4px;
			cursor: pointer;
		}

	}

	.tips {
		margin: 20px;

		.u-title {
			font-weight: bold;
			//width:100px;
			text-align: left;
			margin-bottom: 15px;
			color: red;
		}

		.u-font {
			line-height: 32px;
			color: red;
		}
	}
	.u-label {
		display: inline-block;
		width: 100Px;
		text-align: right;
	}
	.u-flex{display: flex;}
</style>