import request from '@/utils/request';

// 根据文章分类获取文章列表
// 文章分类：1-通知公告；2-VIP攻略；3-新手指南；4、售后规则；6-物流信息
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-70838371
  export const articleListByType = (ArticleType) => {
  	return request.post('/api/article/articleListByType',ArticleType)
  }

// 获取文章详情
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-70838369
  export const getArticleInfo = (data) => {
  	return request.post('/api/article/articleInfo',data)
  }