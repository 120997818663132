<template>
	<el-dialog v-model="dialogVisible" :close-on-click-modal="false" title="物流信息" draggable width="600px">
		<div class="logistics-wrap">
			<div class="logistics-box" v-for="(item, index) in logisticsInfoList" :key="index" >
				<div class="left">
					<div class="circle"></div>
					<div class="line"></div>
				</div>
				<div class="right">
					<div class="info">{{item.context}}</div>
					<div class="time">{{item.time}}</div>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script setup>
	import {
		reactive,
		ref,
		onMounted
	} from 'vue';
	import {
		webOrderLogisticsList,queryTrack
	} from '@/api/user';
	
	
	const logisticsInfoList = ref([]);
	const logisticInfo=reactive({
		deliveryWay:'',
		deliveryNum:'',
		phone:''
	})
	
	const dialogVisible = ref(false);
	const onChangeVisible = async (event) => {
		logisticInfo.deliveryWay=event.DeliveryWay
		logisticInfo.deliveryNum=event.DeliveryNum
		logisticInfo.phone=event.Phone
		await getLogisticsList()
		dialogVisible.value = true;
	};
	defineExpose({
		onChangeVisible
	});
	
	//获取物流轨迹
	const query = reactive({})
	const getLogisticsList= async()=>{
		const {data} = await queryTrack({
			company:logisticInfo.deliveryWay,
			num:logisticInfo.deliveryNum,
			phone:logisticInfo.phone
		})
		const jsonData = JSON.parse(data);
		logisticsInfoList.value = jsonData.data || [];
	}
</script>

<style lang="scss" scoped>
	.logistics-wrap {
		.logistics-box {
			display: flex;
			.left {
				width: 40px;
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				.circle {
					width: 15px;
					height: 15px;
					background: #BEBEBE;
					border-radius: 50%;
				}
				.line {
					width: 2px;
					flex: 1;
					background: #969696;
				}

			}

			.right {
				flex: 1;
				margin-left: 15px;
				margin-bottom: 15px;
				.info {
					font-size: 16px;
					font-weight: 500;
				}

				.time {
					margin-top: 8px;
					font-size: 14px;
					font-weight: 500;
					color: #BEBEBE;
				}
			}
		}

		.logistics-box:first-child{
			.left {
				.circle {
					width: 18px;
					height: 18px;
					background: #FC4D9A;
				}
				.line {
					width: 2px;
					flex: 1;
					background: #FC4D9A;
				}
			}
			.right {
				.info {
					font-size: 18px;
					font-weight: 500;
					color: #333333;
				}
				.time {
					font-size: 16px;
					font-weight: 500;
					color: #BBBBBB;
				}
			}
		}
		.logistics-box:last-child{
			.left {
				.line {
					width: 0px;
					flex: 1;
					background: #969696;
				}
			}
			
		}
	}
</style>