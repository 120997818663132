import request from '@/utils/request';
import xpack from "@/utils/xpack";

// 添加到购物车接口
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-73835089
export const addShopCart = (data = {}) =>{
	return request.post('/api/shoppingCart/addShoppingCart', data, { headers: { showLoading: true } })
}

// 购物车列表接口
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-73835090
export const shoppingCartList = (data = {}) =>{
	return xpack.post('/shoppingCart/shoppingCartList', data)
}

// 保存购物车接口
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-73835091
export const saveShopCart = (data = {}) =>{
	return xpack.post('/shoppingCart/shoppingCartSave', data)
}

// 删除指定购物车接口
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-73835092
export const deleteShopCart = (data = {}) =>{
	return request.post('/api/shoppingCart/shoppingCartDelete', data)
}

// 提交指定购物车接口
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-73835098
export const submitShopCart = (data = {}) =>{
	return request.post('/api/shoppingCart/submitShoppingCart', data)
}

// 清空已过期产品
export const deleteOverdueProduct = (data = {}) =>{
	return request.post('/api/shoppingCart/shoppingCartExpireDelete', data)
}

// 取购物车的预估运费和打包费
export const getCartDeliveryFeeAndPackingFee = (data = {}) =>{
	return xpack.post('/shoppingCart/getCartDeliveryFeeAndPackingFee', data)
}
