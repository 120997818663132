import { userStore } from '@/store/user';
import axios from 'axios';
import { ElMessage } from 'element-plus';

const xpack = axios.create({
  baseURL: process.env.VUE_APP_XPACK_URL,
  timeout: 60000,
  headers: { 'Content-Type': 'application/json;charset=UTF-8' }
});

// 请求拦截器
xpack.interceptors.request.use(
  config => {
    if (userStore().memberInfo) {
      const token = userStore().memberInfo.memberToken;
      if (token) {
        config.headers.token = token;
      }
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器
xpack.interceptors.response.use(
  response => response,
  error => {
    const { errors, message } = error?.response?.data;
    const errorMessage = errors && Array.isArray(errors) ? errors[0].defaultMessage : message;
    ElMessage.error({
      message: errorMessage,
      duration: 2000
    });
    return Promise.reject(error);
  }
);

export default xpack;
