<template>
	<div class="title">提现记录</div>
	<!-- 搜索栏 -->
	<el-form ref="formRef" :model="searchForm" inline>
		<el-form-item label="提现时间：" prop="applyTime">
			<el-date-picker v-model="searchForm.applyTime" type="daterange" start-placeholder="开始时间"
				end-placeholder="结束时间" range-separator="至" :default-value="[new Date()]" clearable
				@change="change(searchForm.applyTime)" />
		</el-form-item>
		<el-form-item label="">
			<el-button type="primary" @click="getWithdrawRecordList">查询</el-button>
			<el-button type="primary" @click="withdrawApply" style="margin-left:15px !important;display:none">申请提现</el-button>
		</el-form-item>
	</el-form>
	<!-- 表格 -->
	<el-table :data="state.data" border style="width: 100%" table-layout="auto" v-loading="state.loading"
		empty-text="没有找到相关信息！" :header-cell-style="{ background:'#f2f2f2',color:'#3a3a3a'}">
		<el-table-column prop="ModifyTime" label="提现时间" align="center" width="140"></el-table-column>
		<el-table-column prop="Amount" label="提现金额" align="center">
			<template #default="scope">
				<span>{{ scope.row.Amount.toFixed(2) }}</span>
			</template>
		</el-table-column>
		<el-table-column prop="ApprovalUser" label="审核人" align="center"></el-table-column>
		<el-table-column prop="WithdrawStatus" label="提现状态" align="center">
			<template #default="scope">
				<span v-if="scope.row.WithdrawStatus==0">申请中</span>
				<span v-if="scope.row.WithdrawStatus==1">审核通过</span>
				<span v-if="scope.row.WithdrawStatus==2">审核失败</span>
			</template>
		</el-table-column>
		<el-table-column prop="Remark" label="备注" align="center"></el-table-column>
	</el-table>
	<div class="u-pagination">
		<el-pagination background :current-page="state.params.limit.conditions.pageStart"
			:page-size="state.params.limit.conditions.pageLength" layout="prev, pager, next" prev-text="上一页"
			next-text="下一页" :total="state.total" @current-change="handleCurrentChange"
			@size-change="handleSizeChange"></el-pagination>
	</div>
	<!-- 申请提现-->
	<withdrawApplyDialog ref="withdrawApplyRef" @update="getWithdrawRecordList"></withdrawApplyDialog>
</template>

<script setup>
	import {
		reactive,
		ref,
		onMounted
	} from 'vue';
	import {
		withdrawList
	} from '@/api/user';
	import moment from 'moment';
	import withdrawApplyDialog from './withdrawApply.vue'
	onMounted(async () => {
		await initData();
	})

	const searchForm = reactive({
		applyTime: '',
		startTime:'',
		endTime:''
	});
	//数据源
	const state = reactive({
		params: {
			filters: {
				enable: true,
				join:'and',
				conditions: []
			},
			limit: {
				enable: true,
				conditions: {
					pageLength: 15,
					pageStart: 1
				}
			}
		},
		data: [], //表格数据
		total: 0,
		loading: true
	})
	
	const changeInputVal=()=>{
		getWithdrawRecordList()
	}
	
	const change = (val) => {
		if (val) {
			const v = JSON.parse(JSON.stringify(val))
			searchForm.startTime = moment(v[0]).format('YYYY-MM-DD')
			searchForm.endTime = moment(v[1]).format('YYYY-MM-DD')
			
		} else {
			searchForm.startTime = ''
			searchForm.endTime = ''
			getWithdrawRecordList()
		}
		
	}
	/**
	 * 初始化数据
	 * @param {无}
	 */
	const initData = async () => {
		await getWithdrawRecordList()
	}
	/**
	 * 获取账单查询列表
	 * @param {无}
	 */
	const getWithdrawRecordList = () => {
		state.params.filters.conditions = [];
		state.loading = true
		state.params.filters.conditions.push(
		{
			name:'ApplyTime',
			type:'dateBetween',
			value:'[\"'+moment(searchForm.startTime).format('YYYY-MM-DD')+'\",\"'+moment(searchForm.endTime).format('YYYY-MM-DD')+'\"]'
		}
		)
		return withdrawList(state.params).then(res => {
			const {
				data: {
					filterAmount,
					records
				}
			} = res
			state.data = records.map(item => {
				return {
					...item
				}
			})
			state.total = filterAmount
			state.loading = false
		})
	}
	
	/**
	 * 申请提现
	 * @param {无}
	 */
	const withdrawApplyRef = ref();
	const withdrawApply = () => {
		withdrawApplyRef.value.onChangeVisable()
	};

	/**
	 * 改变页码
	 * @param {number} val 
	 */
	const handleCurrentChange = (val) => {
		state.params.limit.conditions.pageStart = val;
		getWithdrawRecordList()
	};
	/**
	 * 改变页数限制
	 * @param {number} val 
	 */
	const handleSizeChange = (val) => {
		state.params.limit.conditions.pageLength = val;
		getWithdrawRecordList()
	};
</script>

<style lang="scss" scoped>
	.title {
		font-size: 18px;
		font-weight: bold;
		color: #3A3A3A;
		line-height: 55px;
	}
</style>