import { SALE_TYPE } from "@/common/const.js";
/**
 * 判断是否为下午场预售
  */
export function isPresale(sale_type) {
  if (sale_type == SALE_TYPE.AFTERNOON) {
    return true
  }
  return false
}

/**
 * 映射大类到显示列表
  */
export function mappingMainListToShow(list = []) {
  const template = [
    { main_category: "", sort: 0 }
  ]
  for (let i = 0; i < list.length; i++) {
    const element = list[i];
    template.push({
      main_category: element["MainCategory"] || element["main_category"] || element,
      sort: element["Sort"] || element["sort"] || 0
    })
  }
  console.log("大类", template)
  template.shift();
  return template;
}

/**
 * 映射数据到显示列表
 */
export function mappintSubListToShow(list = []) {
  const template = [
    { sub_category: "", sort: 0 }
  ]

  for (let i = 0; i < list.length; i++) {
    const element = list[i];
    template.push({
      sub_category: element["SubCategory"] || element["name"] || element,
      sort: element["Sort"] || element["sort"] || 0
    });
  }
  template.shift()
  return template;
}

/**
 * 映射活动标签数据到显示列表
 */
export function mappingLabelToShow(list = []) {
  const template = [
    { name: "全部产品", id: "", sort: 0 }
  ]

  for (let i = 0; i < list.length; i++) {
    const element = list[i];
    template.push({
      name: element.Name,
      sort: element.Sort,
      id: element.Id
    })
  }

  return template;
}

