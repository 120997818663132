<template>
	<div class="invoice-wrap">
		<div class="nav-box">
			<router-link to="/index/personalCenter/invoiceCenter/invoiceApplyList"
				active-class="selected-link">申请发票</router-link>
			<router-link to="/index/personalCenter/invoiceCenter/invoiceList"
				active-class="selected-link">发票列表</router-link>
			<router-link to="/index/personalCenter/invoiceCenter/invoiceInfoList"
				active-class="selected-link">我的抬头</router-link>
		</div>
		<div class="main">
			<router-view></router-view>
		</div>
	</div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
	.invoice-wrap {
		.nav-box {
			margin-bottom: 15px;
			a {
				display: inline-block;
				font-size: 20px;
				text-align: center;
				padding: 10px 20px;
				margin-right: 10px;
				border:1px solid #cccccc;
				border-radius: 4px;
				border-left:4px solid #cccccc;
				background-color:#f8f8f8;
			}
			.selected-link {
				color: #FC4D9A;
				border:1px solid #FE5BA3;
				border-left: 4px solid #FE5BA3;
				background-color: #FFFFFF;
				//color:#ffffff;
			}
		}
	}

	
</style>