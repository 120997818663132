<template>
	<div class="article-wrap">
		<div class="title">{{articleInfo.title}}</div>
		<div class="time">{{moment(articleInfo.createTime).format('YYYY-MM-DD')}}</div>
		<div class="content" v-html="articleInfo.content"></div>
	</div>
</template>

<script setup>
	import {
		reactive,
		onMounted
	} from 'vue';
	import { useRoute }  from "vue-router";
	import {getArticleInfo} from '@/api/article';
	import moment from 'moment'
	onMounted(async () => {
		await getArticle()
	});
	
	const route = useRoute();
	const articleInfo=reactive({
		id:'',
		title:'',
		createTime:'',
		content:''
	});
	const getArticle=()=>{
		let id =route.query.id;
		return getArticleInfo({id:id}).then(res => {
			Object.assign(articleInfo, res.data.articleInfo);
		})
	}

</script>

<style lang="scss" scoped>
	.article-wrap{
		text-align: center;
		margin:50px auto;
		padding:0px 50px;
		min-height:800px;
		max-width:1440px;
		.title{
			font-size:30px;
			color:#333;
			font-weight: bold;
		}
		.time{
			font-size:20px;
			color:#BBB;
			padding:20px 0px 20px 0px;
		}
		.content{
			border-top:1px solid #ccc;
			padding:50px 0px;
		}
	}
</style>