<template>
	<el-dialog v-model="dialogVisible" :close-on-click-modal="false" title="抬头信息" draggable width="680px" @close="closeDialog">
		<el-form :model="infoForm" ref="ruleFormRef" label-position="left" >
			<el-form-item label="有效金额(含税):" prop="invoiceType">
				<span class="font-bold">{{'￥'+infoForm.totalEffectivemoney.toFixed(2)}}</span>
			</el-form-item>
			<el-form-item label="服务费(含税):" prop="invoiceType">
				<span class="font-bold">{{'￥'+infoForm.totalServiceFee.toFixed(2)}}</span>
			</el-form-item>
			<el-form-item label="开票金额(含税)：" prop="invoiceType">
				<span class="font-bold">{{'￥'+infoForm.totalInvoiceAmount.toFixed(2)}}</span>
			</el-form-item>
			<el-form-item label="发票类型:" prop="invoiceType">
				<el-radio-group v-model="infoForm.invoiceType" @change="changeInvoiceType">
				    <el-radio :label="1">普票</el-radio>
				    <el-radio :label="2">增值税专用票</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="抬头类型:" prop="titleType" @change="changeTitleType">
				<el-radio-group v-model="infoForm.titleType" >
				    <el-radio :label="1" :disabled="infoForm.invoiceType==2">个人</el-radio>
				    <el-radio :label="2">单位</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="发票材质:" prop="invoiceMode" >
				<el-radio-group v-model="infoForm.invoiceMode">
					<el-radio :label="1">电子版</el-radio>
					<!-- <el-radio :label="2" :disabled="infoForm.invoiceType==1">纸质版</el-radio> -->
				</el-radio-group>
			</el-form-item>
			<el-form-item label="发票抬头:" prop="invoiceTitleId">
				<span v-if="infoForm.invoiceTitleId!==''">{{infoForm.invoiceTypeStr+'-'+infoForm.titleTypeStr+'-'+infoForm.invoiceTitle}}</span>
				<span v-if="infoForm.invoiceTitleId==''" style="color:red;font-weight: bold;">当前未有该类型的发票抬头，请先新增发票抬头,新增后请耐心等待工作人员审核</span>
				<el-button type="primary" @click="addInvoiceInfo" class="add-btn" v-if="infoForm.invoiceTitleId==''">新增抬头</el-button>
			</el-form-item>
			<el-button size="large" type="primary" @click="saveInfo()">提交申请</el-button>
		</el-form>
		<div class="tips-wrap">
			<div class="title">开票须知</div>
			<div class="tip-info">
				<div>1、普通发票和增值税专用发票均为电子发票。</div>
				<div>2、请根据自己的需要选择对应的发票抬头。</div>
				<div>3、“开票金额”为订单的有效金额加上订单服务费（有效金额=订单金额-退款-赔偿；服务费=佣金+运费+纸箱费+配货费+发货服务费+自提配货费）</div>
			</div>
		</div>
		<invoice-info ref="invoiceInfoRef"></invoice-info>
	</el-dialog>
</template>

<script setup>
	import {ref,reactive,onMounted} from 'vue';
	import {ElMessage} from 'element-plus'
	import {webInvoiceSave,webMemberInvoiceTitleList,memberInvoiceTitleInfo} from '@/api/user';
	import invoiceInfo from './invoiceInfo.vue'
	const ruleFormRef = ref();
	const infoForm = reactive({
		orderIds: '',
		totalEffectivemoney:0,
		totalServiceFee:0,
		totalInvoiceAmount:0,
		invoiceType:1,//发票类型:1-普票；2-增值税专用票
		invoiceTypeStr:'',
		titleType:1,//抬头类型：1-个人；2-单位
		titleTypeStr:'',
		invoiceTitleId: '',
		invoiceMode:1,//开票方式：1-电子版；2-纸质版
		invoiceModeStr:'',
		invoiceTitle:''
	});
	
	//更改发票类型时
	const changeInvoiceType =(val)=>{
		if(val==2){
			infoForm.titleType=2
			infoForm.invoiceMode=1
		}else if(val==1){
			infoForm.invoiceMode=1
		}
		getInvoiceTitle()
	}
	
	//更改抬头类型时
	const changeTitleType=(val)=>{
		getInvoiceTitle()
	}
	
	const invoiceTitleInfo=ref({})//打开页面时获取首条抬头的数据
	//打开页面时获取发票抬头
	const getInvoiceTitleInfo=()=>{
		return webMemberInvoiceTitleList({InvoiceType:0,TitleType:0,InvoiceState:1}).then(res => {
			if(res.data.records.length > 0){
				let invoiceTitleInfo=res.data.records[0];
				infoForm.invoiceTitleId=invoiceTitleInfo.Id;
				infoForm.titleType=invoiceTitleInfo.TitleType;
				infoForm.invoiceType=invoiceTitleInfo.InvoiceType;
				infoForm.invoiceMode=invoiceTitleInfo.InvoiceMode;
				infoForm.invoiceTitle=invoiceTitleInfo.InvoiceTitle;
				if(invoiceTitleInfo.TitleType==1){
					infoForm.titleTypeStr="个人"
				}else if(invoiceTitleInfo.TitleType==2){
					infoForm.titleTypeStr="单位"
				}
				if(invoiceTitleInfo.InvoiceType==1){
					infoForm.invoiceTypeStr="普票"
				}else if(invoiceTitleInfo.InvoiceType==2){
					infoForm.invoiceTypeStr="增值税专用票"
				}
			}
		})
	}
	
	//切换发票类型或抬头类型时获取发票抬头
	const getInvoiceTitle = ()=>{
		return webMemberInvoiceTitleList({InvoiceType:infoForm.invoiceType,TitleType:infoForm.titleType,InvoiceState:1}).then(res => {
			if(res.data.records.length > 0){
				let invoiceTitleInfo=res.data.records[0];
				if(invoiceTitleInfo.TitleType==1){
					infoForm.titleTypeStr="个人"
				}else if(invoiceTitleInfo.TitleType==2){
					infoForm.titleTypeStr="单位"
				}
				if(invoiceTitleInfo.InvoiceType==1){
					infoForm.invoiceTypeStr="普票"
				}else if(invoiceTitleInfo.InvoiceType==2){
					infoForm.invoiceTypeStr="增值税专用票"
				}
				infoForm.invoiceTitleId=res.data.records[0].Id;
			}else{
				infoForm.invoiceTitleId='';
			}
		})
	}
	
	
	//保存信息
	const saveInfo = () => {
		if(!infoForm.invoiceTitleId){
			ElMessage({
				message: '请新增发票抬头',
				type: 'error',
				duration:1000
			})
			return
		}
		let res = {
			orderIds:infoForm.orderIds,
			invoiceTitleId:infoForm.invoiceTitleId,
		}
		webInvoiceSave(res).then(() => {
			ElMessage({
				message: '提交成功',
				type: 'success',
				duration:1000
			})
			dialogVisible.value = false;
		})
	};
	
	
	//打开弹窗时
	const dialogVisible = ref(false);
	const onChangeVisible = (totalAmount) => {
		infoForm.orderIds=totalAmount.orderIds,
		infoForm.totalEffectivemoney=totalAmount.totalEffectivemoney,
		infoForm.totalServiceFee=totalAmount.totalServiceFee,
		infoForm.totalInvoiceAmount=totalAmount.totalInvoiceAmount
		getInvoiceTitleInfo();//打开弹窗时获取发票抬头列表的第一条数据
		dialogVisible.value = true;
		
	};
	
	defineExpose({
		onChangeVisible
	});
	
	//关闭弹窗重置表单
	const closeDialog=()=>{
		dialogVisible.value = false;
		ruleFormRef.value.resetFields();
	}
	
	//新增抬头
	const invoiceInfoRef=ref(false);
	const addInvoiceInfo =()=>{
		let params = {
			invoiceType:infoForm.invoiceType,
			titleType:infoForm.titleType,
			invoiceMode:infoForm.invoiceMode
		}
		invoiceInfoRef.value.onChangeVisible(params);
	}
	
	
</script>

<style lang="scss" scoped>
	.font-bold{
		font-weight:bold;
	}
	.add-btn{
		margin-left:50px;
	}
	.tips-wrap{
			margin-top: 30px;
			.title{
				font-size:24px;
				font-weight: bold;
				line-height:48px;
			}
			.tip-info{
				font-size:16px;
				line-height:32px;
				
			}
		}
</style>