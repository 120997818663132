<template>
	<div class="pro-details-wrap">
		<div class="">活动信息</div>
		<!-- 产品信息 -->
		<div class="pro-info-wrap">
			<div class="pro-img-wrap">
				<div class="big-img-box">
					<el-carousel :interval="5000" height="400" @change="handleChange" ref="remarkCarusel">
						<el-carousel-item v-for="(item,index) in proImgList" :key="item" name="index">
							<el-image :src="item.proImg" class="pro-big-img"></el-image>
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="small-img-box">
					<el-image :src="item.proImg" v-for="item in proImgList" :key="item" class="small-img"></el-image>
				</div>
			</div>
			<div class="pro-info-box">
				<div class="base-info">
					<div><text class="u-lable">品牌：</text>{{proBrand}}</div>
					<div><text class="u-lable">品名：</text>{{proName }}</div>
					<div><text class="u-lable">等级：</text>{{proLevel }}</div>
					<div><text class="u-lable">编码：</text>{{proCode }}</div>
					<div><text class="u-lable">颜色：</text>{{proCode }}</div>
					<div><text class="u-lable">装量：</text>{{proCode }}</div>
					<div><text class="u-lable">库存：</text>{{proCode }}</div>
					<div><text class="u-lable">正价：</text>{{proCode }}</div>
					<div><text class="u-lable">底价：</text>{{proCode }}</div>
					<div><text class="u-lable">正价率：</text>{{proCode }}</div>
					<div><text class="u-lable">缺货率：</text>{{proCode }}</div>
					<div><text class="u-lable">投诉率：</text>{{proCode }}</div>
					<div>该产品已被竞购7次</div>
				</div>
				<!-- 基本信息 -->
				<div class="pro-base-info">
					<div v-for="item in proBaseList"><text class="u-lable">{{proBaseName+':'}}</text>{{proBaseInfo}}</div>
					<div><text class="u-lable">备注：</text>{{remark}}</div>
					<div><text class="u-lable">整扎标准：</text>{{remark}}</div>
				</div>
			</div>
		</div>
		<!-- 评价 -->
		<div class="">
			
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		reactive
	} from 'vue';
	const proImgList = reactive([{
			proImg: require('../../assets/image/adImg.png')
		}, {
			proImg: require('../../assets/image/classfyProImg.png')
		},
		{
			proImg: require('../../assets/image/adImg.png')
		},
		{
			proImg: require('../../assets/image/adImg.png')
		}
	]);
	const handleChange = () => {

	}
</script>

<style lang="scss" scoped>
	.pro-info-wrap {
		padding: 30px;
		background-color: #FFF;
		box-shadow: 0px 0px 10px #BBBBBB;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		// justify-content: space-between;
		// flex-wrap: wrap;
		.pro-img-wrap {
			width: 300Px;
			margin-right: 30px;

			.big-img-box {
				width: 300Px;
				min-height: 400Px;
				background-color: #FFF;
				box-shadow: 0px 0px 10px #CCCCCC;
				margin-bottom: 20px;

				.pro-big-img {
					width: 100%;
					height: 100%;
				}
			}

			.small-img-box {
				width: 300Px;
				background-color: #FFF;
				padding: 18px 0px;
				box-shadow: 0px 0px 10px #BBBBBB;

				.small-img {
					width: 68px;
					height: 80px;
					margin-left: 18px;
				}
			}
		}

		.pro-info-box {
			box-shadow: 0px 0px 10px #CCCCCC;
			flex: 1;

			.base-info {
				text{
					display:inline-block;
					width:100px;
					text-align: right;
				}
				font-size: 22px;
				line-height: 40px;
			}
		}
	}
</style>