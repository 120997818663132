import { showFullScreenLoading, tryHideFullScreenLoading } from "@/config/serviceLoading";
import router from '@/router';
import { userStore } from '@/store/user';
import axios from 'axios';
import { ElMessage, ElMessageBox } from 'element-plus';

const request = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	timeout: 60000,
	headers: { 'Content-Type': 'application/json;charset=UTF-8' }
})

// 请求拦截器
request.interceptors.request.use((config) => {
	// * 如果当前请求需要显示 loading,在 api 服务中通过指定的第三个参数: { headers: { showLoading: true } }来控制显示loading
	if (config.headers.showLoading) {
		showFullScreenLoading()
	}
	// 请求使用token
	if (userStore().memberInfo) {
		const token = userStore().memberInfo.memberToken;
		if (token) {
			config.headers.token = token
		}
	}
	return config
}, error => {
	return Promise.reject(error)
});

// 响应拦截器
request.interceptors.response.use(response => {
	if (response.status !== 200) {
		return Promise.reject(new Error(response.statusText || 'Error'))
	}

	// * 在请求结束后，并关闭请求 loading
	tryHideFullScreenLoading();

	const res = response.data
	// 响应成功
	if ([0, 6, 7, 8, 9, 10, 11, 16].includes(res.code)) {
		if (res.code == 16) {
			ElMessage.warning(res.message)
		}
		return res
	} else if (res.code == 15) {
		let notice = res.message;
		ElMessageBox({
			title: '温馨提示',
			message: notice
		})
	} else if (res.code === 20000) {
		userStore().memberInfo = '';
		router.replace({
			path: '/login'
		})
	} else {
		// 错误提示
		ElMessage.error({
			message: res.message,
			duration: 2000
		})
	}
	const path = router.currentRoute.value.fullPath
	const routes = ['/index/orderProduct'] // 需要放行错误的路由
	if (routes.includes(path)) {
		return res
	}

	return Promise.reject(new Error(res.message || 'Error'))
	// return response
}, error => {
	tryHideFullScreenLoading();
	/* ElMessage.error({
		message:res.message,
		duration:1000
	}) */
	return Promise.reject(error)
});

export default request;
