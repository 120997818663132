<template>
  <el-form ref="phoneRef" :model="phoneLoginForm" size="large" class="u-m-t-15">
    <el-form-item prop="memberPhone">
      <el-input v-model="phoneLoginForm.memberPhone" type="text" :prefix-icon="User" placeholder="请输入手机号"></el-input>
    </el-form-item>
    <el-form-item prop="smsCode">
      <el-input v-model="phoneLoginForm.smsCode" :prefix-icon="CircleCheck" type="text" placeholder="请输入验证码"
        :class="{ 'input-width-70': !sms.disabled, 'input-width-60': sms.disabled }"></el-input>
      <el-button v-if="!sms.disabled" type="primary" @click="sendCode"
        style="margin-left:10px !important;">发送验证码</el-button>
      <el-button v-else type="primary" disabled style="margin-left:10px !important;">{{ sms.count }}
        秒后重新发送</el-button>
    </el-form-item>
    <div class="u-center">
      <el-button class="sumbit-btn" type="primary" @click="submitPhoneLoginForm(phoneRef)"
        @keydown.enter="keyPhoneDown(phoneRef)">登录</el-button>
      <div class="ohter-box">
        <el-checkbox v-model="isRemember">记住手机号码</el-checkbox>
        <span>
          <router-link to="/register">注册</router-link> | <router-link to="/retrievePassword">忘记密码</router-link>
        </span>
      </div>
    </div>
  </el-form>
  <!-- 会员充值 -->
  <member-recharge-dialog ref="rechargeRef"></member-recharge-dialog>
</template>

<script setup>
import {
  ref,
  reactive,
  onMounted,
  onUnmounted
} from "vue";
import {
  User,
  CircleCheck
} from '@element-plus/icons-vue'
import {
  ElMessage,
  ElMessageBox
} from 'element-plus';
import {
  useRouter
} from 'vue-router';
import {
  useSendCode,
  phoneLogin,
  handleAnnualFee
} from '@/api/user';
import memberRechargeDialog from '@/components/recharge-modal/index.vue'
import {
  userStore
} from '@/store/user';
const router = useRouter();
const userInfo = userStore();
//手机登录
const phoneRef = ref();
// 会员充值
const rechargeRef = ref();

const phoneLoginForm = reactive({
  memberPhone: '',
  smsCode: ''
});

const phoneRules = reactive({
  memberPhone: [{
    required: true,
    message: '请输入手机号',
    trigger: 'blur'
  }, {
    pattern: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/,
    message: '手机号码格式不正确',
    trigger: 'blur'
  }],
  smsCode: [{
    required: true,
    message: '请输入验证码',
    trigger: 'blur'
  },]
});
// 记住手机号码
const isRemember = ref(false);
onMounted(() => {
  //绑定监听事件
  document.addEventListener('keydown', keyPhoneDown);
  let memberPhone = localStorage.getItem('memberPhone');
  if (memberPhone) {
    phoneLoginForm.memberPhone = localStorage.getItem('memberPhone');
    isRemember.value = true;
  }
});
onUnmounted(() => {
  //销毁事件
  document.removeEventListener('keydown', keyPhoneDown, false)
});
// 发送短信验证码
const sendCode = () => {
  useSendCode({
    memberPhone: phoneLoginForm.memberPhone
  }).then(() => {
    timerHandler()
  })
}

// 短信计时器
const sms = reactive({
  disabled: false,
  total: 300,
  count: 0
})

// 计时器处理器
const timerHandler = () => {
  sms.count = sms.total
  sms.disabled = true

  let timer = setInterval(() => {
    if (sms.count > 1 && sms.count <= sms.total) {
      sms.count--
    } else {
      sms.disabled = false
      clearInterval(timer)
    }
  }, 1000)
}
//登录
const submitPhoneLoginForm = () => {
  if (isRemember.value == true) {
    localStorage.setItem('memberPhone', phoneLoginForm.memberPhone);
  } else {
    localStorage.removeItem("memberPhone");
  }
  userInfo.phoneLogin(phoneLoginForm).then(() => {
    if (userStore().code == 0) {
      ElMessage({
        message: '登录成功',
        type: 'success',
        duration: 1000
      });
      setTimeout(() => {
        router.push({
          path: '/notice'
        })
      }, 1000);
    }
  })
}
//点击回车键登录
const keyPhoneDown = (e) => {
  if (e.keyCode == 13) {
    submitPhoneLoginForm()
  }
}
</script>


<style lang="scss" scoped>
@import '@/assets/style/login.scss';

.input-width-60 {
  width: 60% !important;
}

.input-width-70 {
  width: 70% !important;
}
</style>
