import { userStore } from '@/store/user';
import axios from 'axios';
import { ElMessage } from 'element-plus';

const xrequest = axios.create({
  baseURL: process.env.VUE_APP_XREQUEST_URL,
  timeout: 60000,
  headers: { 'Content-Type': 'application/json;charset=UTF-8' }
})

// 请求拦截器
xrequest.interceptors.request.use((config) => {
  // 请求使用token

  if (userStore().memberInfo) {
    const token = userStore().memberInfo.memberToken;
    if (token) {
      config.headers.Token = token
    }
  }
  return config
}, error => {
  return Promise.reject(error)
});

function dealError(error) {
  const { errors, message, msg } = error?.response?.data;
  const errorMessage = errors && Array.isArray(errors) ? errors[0].defaultMessage : (message ? message : msg);
  ElMessage.error({
    message: errorMessage,
    duration: 2000
  });
}

// 响应拦截器
xrequest.interceptors.response.use(response => {
  return Promise.resolve(response);
}, error => {
  console.log("gggggggg", error)
  dealError(error);
  return Promise.reject(error)
});

export default xrequest;
