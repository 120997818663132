<template>
	<el-dialog v-model="dialogVisible" :close-on-click-modal="false" title="开票信息" draggable width="550px">
		<el-table :data="state.data" border style="width: 100%" v-loading="state.loading" empty-text="没有找到相关信息！"
			:header-cell-style="{ background:'#f2f2f2',color:'#3a3a3a'}">
			<el-table-column prop="orderNum" label="订单号" align="center"></el-table-column>
			<el-table-column prop="productCost" label="有效金额" align="center"></el-table-column>
			<el-table-column prop="freightCost" label="服务费" align="center"></el-table-column>
			<el-table-column prop="invoiceAmount" label="可开票额" align="center"></el-table-column>
		</el-table>
	</el-dialog>
</template>

<script setup>
	import {reactive,ref} from 'vue';
	import {webInvoiceList} from '@/api/user';
	
	//开票订单数据源
	const invoiceId=ref('');
	const state = reactive({
		params: {
			filters: {
				enable: true,
				join: 'and',
				conditions: [
					{
						name: 'Id',
						type: 'eq',
						value:invoiceId
					}
				]
			}
		},
		data: [], //表格数据
		loading: true
	})
	/**
	 * 获取开票订单列表
	 * @param {无}
	 */
	const getInvoiceDetailList = () => {
		state.loading = true;
		return webInvoiceList(state.params).then(res => {
			state.data = res.data.records[0].InvoiceItemList;
			state.loading = false;
		})
	}
	//打开弹窗时
	const dialogVisible = ref(false);
	const onChangeVisible = (Id) => {
		invoiceId.value= Id;
		console.log('invoiceId.value', invoiceId.value)
		getInvoiceDetailList();
		dialogVisible.value = true;
	};

	defineExpose({
		onChangeVisible
	});
</script>

<style lang="scss" scoped>
	
</style>