<template>
  <div class="login-box">
    <div class="link-wrap">
      <router-link to="/login/account" active-class="selected-link">密码登录</router-link>
      <router-link to="/login/phone" active-class="selected-link">短信登录</router-link>
    </div>
    <router-view></router-view>
  </div>
</template>

<script setup>
import { ref } from "vue";
import useAccountLogin from './login/account.vue'
import usePhoneLogin from './login/phone.vue'
const activeName = ref('account');
</script>

<style lang="scss">
@import '@/assets/style/login.scss';

.login-box {
  .link-wrap {
    border-bottom: 1px solid #E0E4EB;
    margin-bottom: 50px;

    a {
      padding: 15px 0px;
      display: inline-block;
      width: 50%;
      font-size: 24px;
      font-weight: 400;
      color: #AAAAAA;
      line-height: 48px;
      text-align: center;
    }

    .selected-link {
      font-size: 24px;
      font-weight: 400;
      color: #FC4D9A;
      line-height: 48px;
      border-bottom: 4px solid #FC4D9A;
      padding: 15px 0px;
    }
  }
}
</style>
