<template>
  <view>
    <!-- 搜索栏 -->
    <searchBar
      :tomorrow_total="state.tomorrow_total"
      v-model:sale_type="state.sale_type"
      v-model:color="state.color"
      v-model:brand="state.brand"
      v-model:brand_code="state.brand_code"
      v-model:grade="state.grade"
      v-model:sale_date="state.sale_date"
      :main_category="main_category"
      :sub_category="sub_category"
      @update:keywords="updateKeywords"
    ></searchBar>

    <!-- 面包屑 -->
    <breadcrumb
      :main_category="main_category"
      :sub_category="sub_category"
      :label="label"
      @update:main_category="updateMainCategory"
      @update:sub_category="updateSubCategory"
      @update:label="updateLabel"
      v-model:color="state.color"
      v-model:brand="state.brand"
    >
    </breadcrumb>

    <!-- 表格 -->
    <productList
      :keywords="state.keywords"
      :product_name="state.product_name"
      :is_new="state.is_new"
      :brand_type="state.brand_type"
      :brand="state.brand"
      :color="state.color"
      :grade="state.grade"
      :sale_date="state.sale_date"
      :brand_code="state.brand_code"
      :tag_id="state.tab_id"
      :main_category="main_category"
      :sub_category="sub_category"
      :label="label"
      :sort="state.sort"
      :price_sort="state.price_sort"
      :timely_count_sort="state.timely_count_sort"
      :sale_count_sort="state.sale_count_sort"
      :sale_type="state.sale_type"
      :is_my_love="state.is_my_love"
      @updateSort="onUpdateSort"
    >
    </productList>
  </view>
</template>

<script setup>
import searchBar from './components/search-bar/index.vue';
import breadcrumb from './components/breadcrumb/index.vue';
import productList from './components/product-list/index.vue';
import { reactive, ref, watch } from 'vue';
import { SALE_TYPE } from '@/common/const';
import { getAfterNoonSaleDate } from '@/common';

const props = defineProps({
  main_category: {
    type: String,
    default: ''
  },
  sub_category: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  }
});

const state = reactive({
  keywords: '',
  sale_type: SALE_TYPE.AFTERNOON,
  sale_date: getAfterNoonSaleDate(),
  color: '',
  brand: '',
  brand_code: '',
  grade: '',
  is_new: 0,
  brand_type: 0,
  tab_id: '',
  is_my_love: 0,
  sort: 1,
  price_sort: 0,
  timely_count_sort: 0,
  sale_count_sort: 0,
  product_name: ''
});

const emit = defineEmits(['update:main_category', 'update:sub_category', 'update:label', 'updateSaleType']);

watch(
  () => state.sale_type,
  (newValue, oldValue) => {
    (state.sort = 0), (state.price_sort = 0), (state.timely_count_sort = 0), (state.sale_count_sort = 0);
    emit('updateSaleType', state.sale_type);
  }
);

function updateLabel(event) {
  emit('update:label', event);
}

function updateSubCategory(event) {
  emit('update:sub_category', event);
}

function updateMainCategory(event) {
  emit('update:main_category', event);
}

function updateKeywords(params) {
  state.keywords = params;
}

function onUpdateSort(e) {
  console.log('更新排序', e);
  state.sort = e.price_sort != 0 || e.sale_count_sort != 0 || e.timely_count_sort != 0 ? 1 : 0;
  state.price_sort = e.price_sort;
  state.timely_count_sort = e.timely_count_sort;
  state.sale_count_sort = e.sale_count_sort;
}
</script>

<style lang="scss" scoped></style>
