<template>
	<div>
		<h3 style="text-align: center;"><strong>缤纷鲜花隐私政策</strong></h3>
		<!-- <p style="text-align: right;">最近更新日期：2022年09月13日</p> -->
	</div>
	<p>&nbsp;</p>
	<p class="title"><strong>提示条款</strong></p>
	<p>您的信任对我们非常重要，我们深知个人信息对您的重要性，我们将按法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。鉴此，缤纷鲜花网服务提供者（或简称&ldquo;我们&rdquo;）制定本《隐私政策》（下称&ldquo;本政策&rdquo;）并提醒您：
	</p>
	<p>本政策适用于缤纷鲜花网产品或服务。<strong>如我们关联公司（范围详见定义部分）的产品或服务中使用了缤纷鲜花网提供的产品或服务但未设独立的隐私政策的，则本政策同样适用于该部分产品或服务</strong>。</p>
	<p>需要特别说明的是，<strong>本政策不适用于其他第三方向您提供的服务，第三方向您提供的服务适用其向您说明的隐私权政策。</strong></p>
	<p><strong>在使用缤纷鲜花网各项产品或服务前，请您务必仔细阅读并透彻理解本政策，</strong><strong>&nbsp;特别是以粗体/粗体下划线标识的条款，您应重点阅读，&nbsp;</strong><strong>在确认充分理解并同意后使用相关产品或服务。一旦您开始使用缤纷鲜花网各项产品或服务，即表示您已充分理解并同意本政策。</strong>
	</p>
	<p class="title"><strong>定义</strong></p>
	<p><strong>缤纷鲜花网</strong>：指缤纷鲜花网站（所涉域名为 brightenflower.com、brightenflower.cn）及客户端（含小程序和APP）。</p>
	<h3 class="title">一、总则</h3>
	<div>
		<p>1.1 用户在注册之前，应当仔细阅读本协议，并同意遵守本协议后方可成为注册用户。一旦注册成功，则用户与缤纷鲜花网之间自动形成协议关系，用户应当受本协议的约束。用户在使用特殊的服务或产品时，应当同意接受相关协议后方能使用。</p>
		<p>1.2 本协议内容包括本协议正文、及所有已经发布或将来可能在缤纷鲜花网前台发布的各类规则、规定、公告或通知（以下合称“规则”）。所有规则为本协议缤纷鲜花网不可分割的组成部分，与协议正文具有同等法律效力。</p>
		<p>1.3 本平台有权根据需要不时地制订、修改本协议及/或各类规则，并以网站公示的方式进行变更，不在另外单独通知您。变更后的协议和规则一经在网站公布后，立即或在公告明确的特定时间自动生效。若您在前述变更公告后继续使用本平台服务的， 即表示您已经阅读、理解并接受经修订的协议和规则。若您不同意相关变更，应当立即停止使用本平台相关的服务。</p>
	</div>
	<h3 class="title">二、服务内容</h3>
	<div>
		<p>2.1 缤纷鲜花网是缤纷园艺旗下平台，是供鲜花生产者和花店、批发商等花卉从业者使用的鲜切花销售平台，实行会员制，凭本人会员号及密码，或电话号码通过短信验证码验证身份上网交易。</p>
		<p>2.2 在缤纷鲜花网上申请成为本网会员，经过客户经理验证收货地址后方可交易。</p>
		<p>2.3 会员号只允许所属会员本人使用。会员账号和密码是会员的重要个人资料，需认真设置、管理与使用，凡使用本网帐户和密码办理的业务,均视为会员本人亲自操作。</p>
		<p>2.4 会员在网上交易与操作，须遵守缤纷鲜花的《交易规则》及随时修改的使用条款，违者将终止会籍，不做任何补偿。</p>
		<p>2.5 注册成为会员视为理解并同意本《隐私政策》。</p>
	</div>
	<h3 class="title">三、用户帐号</h3>
	<div>
		<p>3.1 经本站注册系统完成注册程序并通过身份认证的用户即成为正式用户，可以获得本站规定用户所应享有的一切权限；未经认证仅享有本站规定的部分会员权限。缤纷鲜花网有权对会员的权限设计进行变更。</p>
		<p>3.2 用户只能按照注册要求使用真实姓名，及手机号注册。用户有义务保证密码和帐号的安全，用户利用该密码和帐号所进行的一切活动引起的任何损失或损害，由用户自行承担全部责任，本站不承担任何责任。如用户发现帐号遭到未授权的使用或发生其他任何安全问题，应立即修改帐号密码并妥善保管，如有必要，请通知本站。因黑客行为或用户的保管疏忽导致帐号非法使用，本站不承担任何责任。</p>
	</div>
	<h3 class="title">四、使用规则</h3>
	<p>4.1 遵守中华人民共和国相关法律法规，包括但不限于《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《最高人民法院关于审理涉及计算机网络著作权纠纷案件适用法律若干问题的解释(法释[2004]1号)》、《全国人大常委会关于维护互联网安全的决定》、《互联网电子公告服务管理规定》、《互联网新闻信息服务管理规定》、《互联网著作权行政保护办法》和《信息网络传播权保护条例》等有关计算机互联网规定和知识产权的法律和法规、实施办法。</p>
	<p>4.2 用户对其自行发表、上传或传送的内容负全部责任，所有用户不得在本站任何页面发布、转载、传送含有下列内容之一的信息，否则本站有权自行处理并不通知用户：</p>
	<p>(1)违反宪法确定的基本原则。</p>
	<p>(2)危害国家安全，泄漏国家机密，颠覆国家政权，破坏国家统一的。</p>
	<p>(3)损害国家荣誉和利益的。</p>
	<p>(4)煽动民族仇恨、民族歧视，破坏民族团结的。</p>
	<p>(5)破坏国家宗教政策，宣扬邪教和封建迷信的。</p>
	<p>(6)散布谣言，扰乱社会秩序，破坏社会稳定的。</p>
	<p>(7)散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的。</p>
	<p>(8)侮辱或者诽谤他人，侵害他人合法权益的。</p>
	<p>(9)煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的。</p>
	<p>(10)以非法民间组织名义活动的。</p>
	<p>(11)含有法律、行政法规禁止的其他内容的。</p>
	<p>4.3 用户承诺对其发表或者上传于本站的所有信息(即属于《中华人民共和国著作权法》规定的作品，包括但不限于文字、图片、音乐、电影、表演和录音录像制品和电脑程序等)均享有完整的知识产权，或者已经得到相关权利人的合法授权；如用户违反本条规定造成本站被第三人索赔的，用户应全额补偿本站一切费用(包括但不限于各种赔偿费、诉讼代理费及为此支出的其它合理费用)。</p>
	<p>4.4 当第三方认为用户发表或者上传于本站的信息侵犯其权利，并根据《信息网络传播权保护条例》或者相关法律规定向本站发送权利通知书时，用户同意本站可以自行判断决定删除涉嫌侵权信息，除非用户提交书面证据材料排除侵权的可能性，本站将不会自动恢复上述删除的信息；</p>
	<p>(1)不得为任何非法目的而使用网络服务系统。</p>
	<p>(2)遵守所有与网络服务有关的网络协议、规定和程序。</p>
	<p>(3)不得利用本站进行任何可能对互联网的正常运转造成不利影响的行为。</p>
	<p>(4)不得利用本站进行任何不利于本站的行为。</p>
	<p>4.5 如用户在使用网络服务时违反上述任何规定，本站有权要求用户改正或直接采取一切必要的措施(包括但不限于删除用户张贴的内容、暂停或终止用户使用网络服务的权利)以减轻用户不当行为而造成的影响。</p>
	<h3 class="title">五、其他协议条款</h3>
	<p>5.1 如需开具发票请干订单完成 2 个月内在网上申请开具电子发票,如超过时间未自助申请视为自动放弃开具发票，平台将不予补开逾期的发票;如未提供正确的开票信息，平台对此不承担任何责任，您将承担因此产生的任何直接或间接损失及不利后果。"</p>
	<p>5.2 发票抬头必须与您的会员名称一致，否则抬头无效;当抬头与您的会员名称不一致时。恕本公司不能开与您发票。</p>
	<h3 class="title">六、我们如何收集和使用您的个人信息</h3>
	<p><span style="line-height: 2;">&nbsp;&nbsp;&nbsp;&nbsp;</span><span
			style="line-height: 2;">个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</span><br /><span
			style="line-height: 2;"> 广州缤纷园艺有限公司仅会出于本政策所述的以下目的，收集和使用您的个人信息：</span><br /><span style="line-height: 2;">
			为更好地为您提供服务，</span><span style="line-height: 2;">广州缤纷园艺</span><span
			style="line-height: 2;">有限公司将遵循&ldquo;合法、正当、必要&rdquo;原则，按照法律法规的规定和您的同意收集您的个人信息，主要包括：&nbsp;</span><br /><span
			style="line-height: 2;">我们收集的个人信息</span><br /><span style="line-height: 2;">
			当在你使用本客户端服务的过程中，本客户端会按照如下方式收集你在使用服务时主动提供或因为使用服务而产生的信息，用以向你提供服务以及保障你的帐号安全：</span><br /><strong><span
				style="line-height: 2;"> （1）登录功能</span></strong><br /><span style="line-height: 2;">
			您使用本客户端的短信验证码登录、一键登录、自动登录功能时，为了保证登录服务正常运行，验证使用设备及登录身份的合法性和安全性，我们需要访问您的<strong>终端MAC地址、IMEI信息、SIM卡信息、Android
				ID、SSID、IMSI信息、终端版本型号、网络IP、本机号码、网络类型、手机品牌</strong>。</span></p>
	<p><span style="line-height: 2;"><strong>（2）商品/服务信息展示和搜索</strong></span></p>
	<p><span
			style="line-height: 2;"><strong>为了向您展示商品或者服务，我们会收集您的设备信息（包括设备名称、设备型号、MAC地址及IMEI、设备识别码、移动应用列表等软硬件特征信息、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络ID（PLMN））、设备所在位置相关信息（包括您授权的GPS位置信息以及WLAN接入点、蓝牙和基站传感器信息）、浏览器类型来对相应的商品或者服务内容进行排序以适应您的设备界面。</strong></span>
	</p>
	<p><span style="line-height: 2;"><strong>（3）下单及订单管理</strong></span></p>
	<p><span
			style="line-height: 2;"><strong>订单中至少填写您的收货人姓名、收货地址以及手机号码，同时该订单中会载明订单号、您所购买的商品或服务信息、下单时间、您应支付货款金额、佣金、运费、配货打包费、发货服务费等，货款金额和佣金截单时从您的会员账户余额中扣除，配货打包费、运费、发货服务费将在您下单时根据货品情况预估费用冻结一部分账户余额，实际费用以第二早扣费为准。您可以另外填写收货人的联系电话、邮箱地址信息以增加更多的联系方式确保商品可以准确送达，但不填写这些信息不影响您订单的生成。</strong></span>
	</p>
	<p><span style="line-height: 2;"><strong>若您需要开具发票，还需要提供发票抬头、纳税人识别号以及接收发票的电子邮箱。</strong></span></p>
	<p><span style="line-height: 2;"><strong>（4）支付功能</strong></span></p>
	<p><span style="line-height: 2;"><strong>下订单前，您需要往账户充值金额才能进行订购或竞购。</strong></span>
	</p>
	<h3 class="title">七、您个人信息使用的规则</h3>
	<p>1、我们会根据本隐私政策的内容，为实现我们的产品与/或服务功能对所收集的个人信息进行使用。</p>
	<p>2、在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理。</p>
	<p>3、当我们展示您的个人信息时，我们会采用包括去标识化或者匿名化处理方式对您的信息进行脱敏，以保护您的信息安全。</p>
	<p>4、当我们要将您的个人信息用于本隐私政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会事先征求您的明示同意。</p>
	<h3 class="title">八、您如何管理您的个人信息</h3>
	<p>我们非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：</p>
	<h3>1、访问和更正您的个人信息</h3>
	<p>除法律法规规定外，您有权随时访问和更正您的个人信息，具体包括：</p>
	<p>（1）您的账号信息：PC端您可以在&ldquo;会员中心&rdquo;--&ldquo;我的资料&rdquo;页面中查阅您提交给我们的个人信息，你也可通过&ldquo;修改密码&rdquo;页面进行登录密码的修改。</p>
	<p>（2）您的发票信息：您可以访问或更新或清除您的发票信息，移动端路径为首页--&ldquo;我的&rdquo;--&ldquo;发票&rdquo;；PC端为&ldquo;会员中心&rdquo;--&ldquo;发票中心&rdquo;。
	</p>
	<p>对于您在使用我们的产品与/或服务过程中产生的其他个人信息需要访问或更正，请随时联系我们。我们会根据本隐私政策所列明的方式和期限响应您的请求。</p>
	<h3>2、删除您的个人信息</h3>
	<p>您在我们的产品与/或服务页面中可以直接清除或删除的信息，包括订单信息、浏览信息、收货地址信息。</p>
	<p><strong>在以下情形中，您可以向我们提出删除个人信息的请求：</strong></p>
	<p>（1）如果我们处理个人信息的行为违反法律法规；</p>
	<p>（2）如果我们收集、使用您的个人信息，却未征得您的同意；</p>
	<p>（3）如果我们处理个人信息的行为严重违反了与您的约定；</p>
	<p>（4）如果您不再使用我们的产品或服务，向我们提出删除申请，我们将在7个工作日内完成处理；</p>
	<p>（5）如果我们终止服务及运营，或者保存期限已届满。</p>
	<p>若我们决定响应您的删除请求，我们还将同时尽可能通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体已获得您的独立授权。</p>
	<p><strong>请您注意，当您成功注销缤纷鲜花账号后，我们将对您的个人信息进行删除或匿名化处理。当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到可以删除或实现匿名化。</strong>
	</p>
	<h3 class="p1">3、注销账号</h3>
	<p><strong>您可以在我们的产品中申请注销账号。PC端路径为&ldquo;订购产品&rdquo;--&ldquo;客户经理&rdquo;，联系您的专属客户经理提出注销账户要求，我们将在7个工作日内完成处理。</strong>
	</p>
	<h3 class="title">九、如何联系我们</h3>
	<p>1、如您对本隐私政策或您个人信息的相关事宜或者投诉、举报、有任何问题、意见或建议，请通过以下方式与我们联系。</p>
	<p>邮箱：info@brighten.cn</p>
	<p>联系电话：【020-81608312】</p>
	<p>联系地址：广州市荔湾区龙溪大道广州花卉博览园批发市场办公楼二楼201号</p>
	<p><strong>请您理解，我们向您提供的产品与/或服务将不断更新变化。如果您选择使用本隐私政策中尚未列明的其他产品与/或服务时，我们会在收集您的个人信息前通过协议、页面提示等方式向您详细说明信息收集的目的、方式、范围并征求您的同意。若您不同意提供前述信息，您可能无法使用该项产品与/或服务，但不影响您使用现有产品与/或服务。</strong>
	</p>
	<h3 class="title">十、第三方信息共享清单</h3>
	<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;当你使用第三方提供的服务时，我们会在获得或确保第三方获得你的授权同意后，以及其他符合法律法规规定的情形下共享对应信息。你可以通过本清单中列举的相关信息了解第三方会如何处理你的个人信息。我们也会对第三方获取个人信息的行为进行严格约束，以保护你的个人信息安全。
	</p>
	<p><br /></p>
	<p> <strong>支付宝SDK</strong><br /></p>
	<p> 第三方主体：支付宝（中国）网络技术有限公司</p>
	<p> 共享的信息：设备信息</p>
	<p> 使用场景：用户使用支付宝支付时</p>
	<p> 使用的目的：满足用户在应用内使用支付宝支付的场景</p>
	<p> 共享方式：后台接口传输</p>
	<p> 第三方个人信息处理规则：<a href="https://render.alipay.com/p/yuyan/180020010001196791/preview.html?agreementId=AG00000132"
			target="_blank">[链接]</a></p>
	<p> <br /></p>
	<p> <strong>微信支付/网络支付服务</strong></p>
	<p> 第三方主体： 财付通支付科技有限公司</p>
	<p> 共享的信息：
		微信登录信息，包括微信号及用户标识信息、手机号、QQ号码、邮箱；微信帐号昵称、备注名、头像及用户标识信息；IP地址、设备识别码；硬件设备型号、终端系统类型、设备识别码、使用的软件版本信息、软件语言设置信息、所在的地区</p>
	<p> 使用场景： 用户交易功能</p>
	<p> 使用目的： 用户使用微信支付服务</p>
	<p> 共享方式： 后台接口传输</p>
	<p> 第三方个人信息处理规则：<a href="https://www.tenpay.com/v3/helpcenter/low/privacy.shtml" target="_blank">[链接]</a></p>
	<p> <br /></p>
	<p> <strong>微信登录OpenSDK</strong></p>
	<p> 第三方主体：深圳市腾讯计算机系统有限公司</p>
	<p> 共享的信息： 具体根据用户登录其他APP时授权提供的信息范围（如头像、昵称）</p>
	<p> 使用场景： 使用微信登录时</p>
	<p> 使用目的： 满足用户使用微信登录其他APP</p>
	<p> 共享方式： 后台接口传输</p>
	<p> 第三方个人信息处理规则：<a href="https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8"
			target="_blank">OpenSDK隐私政策</a></p>
	<p> <br /></p>
</template>

<script>
</script>

<style>
	.title_xy {
		text-align: center;
		font-size: 20px;
		color: #3C3C3C;
		padding-top: 20px;
		padding-bottom: 10px;
	}

	.desc_title h1 {
		font-weight: normal;
		margin: 5px 10px;
		font-size: 18px;
		color: #353535;
	}

	.desc_content {
		margin: 0 20px;
		font-size: 16px;
		color: #353535;
	}
	.title{line-height:42px;}
</style>