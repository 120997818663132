<template>
  <div v-show="show">
    <el-table
      :data="state.list"
      v-loading="state.loading"
      border
      empty-text="没有找到相关信息！"
      show-summary
      style="width: 100%"
      size="small"
      table-layout="auto"
      class="u-m-b-15"
    >
      <el-table-column label="品名" align="center" header-align="center">
        <template #default="scope">{{ scope.row.Brand + scope.row.ProductName + ' ' + scope.row.Grade }}</template>
      </el-table-column>
      <el-table-column label="出价" align="center">
        <template #default="scope">
          <el-input-number
            size="small"
            :min="scope.row.oldOrderPrice"
            :precision="2"
            v-model="scope.row.OrderPrice"
            @change="onEditOrder(scope.row, 0)"
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="数量" align="center" prop="OrderCount">
        <template #default="scope">
          <el-input-number
            size="small"
            :min="scope.row.oldOrderCount"
            v-model="scope.row.OrderCount"
            @change="onEditOrder(scope.row, 1)"
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="金额" align="center" prop="OrderAmount" width="60">
        <template #default="scope">{{ scope.row.OrderAmount.toFixed(2) }}</template>
      </el-table-column>
      <el-table-column label="佣金" align="center" prop="Commission" width="60">
        <template #default="scope">{{ scope.row.Commission.toFixed(2) }}</template>
      </el-table-column>
      <!-- <el-table-column label="操作" align="center" width="50">
			<template #default="scope">
				<i style="font-size: 20px" class="iconfont icon-bianji" @click="onEditOrder(scope.row)"></i>
			</template>
		</el-table-column> -->
    </el-table>
    <div class="sum-box">
      <div v-if="state.totalize.ShippingTitle">
        运费方案：{{ state.totalize.ShippingTitle }}（{{ memberInfo.logistics }}-{{ memberInfo.addressCity }}）
      </div>
      <div v-if="state.totalize.PredictTitle">
        {{ [0, 2].includes(state.totalize.ShippingType) ? '预估重量' : '预估件' }}：{{ state.totalize.PredictTitle }}
      </div>
      <div v-if="state.totalize.TotalDeliveryFee">预估运费：{{ state.totalize.TotalDeliveryFee }}</div>
      <div v-if="state.totalize.TotalPackingFee">预估配货装箱费：{{ state.totalize.TotalPackingFee }}</div>
      <div v-if="state.totalize.TotalDiscount">折扣：{{ state.totalize.TotalDiscount }}</div>
      <div v-if="state.totalize.TotalGiftOuantity">赠送数量：{{ state.totalize.TotalGiftOuantity }}</div>
      <div v-if="state.totalize.TotalAcutalAmount"
        >预估金额：
        <span style="color: red; font-weight: bold">{{ state.totalize.TotalAcutalAmount }}</span>
      </div>
    </div>
    <div class="tips-box">
      <div class="text-bold">竞购提示：</div>
      <div class="tips">1、拍售时间:开盘期间 (12:30-次日12:00) 都可拍售，拍售结果查询：发货当日12:20。</div>
      <div class="tips">2、拍售即为竞购，价高者得，对于必要产品请选择订购，竞购成功后竞购产品会显示在发货订单内。</div>
      <div class="tips"
        >3、竞购时可点击产品竞购单中的“修改”按钮对竞购产品进行修改，修改时数量和出价只能在原有基础上增加，参与竞购的产品不可再退出竞购。
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, watch } from 'vue';
import { ElMessage } from 'element-plus';
import { getOrderBidList, updateOrderBidSave } from '@/api/productSale';
import { userStore } from '@/store/user';

const props = defineProps({
  memberInfo: {
    type: Object,
    default: {}
  },
  show: {
    type: Boolean,
    default: true
  }
});

const userInfo = userStore();

onMounted(() => {
  console.log('进入现售订单列表');
  getBidList();
});

/**
 * 取竞购订单列表
 * @param {无}
 */
const state = reactive({
  loading: true,
  list: [],
  totalize: {}
});

const getBidList = async () => {
  Object.keys(state.totalize).forEach(key => (state.totalize[key] = 0));
  const {
    data: { records }
  } = await getOrderBidList();
  state.totalize = { ...state.totalize, ...[...records].shift() };
  state.list = records.map(item => {
    return { ...item, oldOrderPrice: item.OrderPrice, oldOrderCount: item.OrderCount };
  });
  state.loading = false;
};

/**
 * 修改订单
 * @param {object} event
 * @param {Number} index
 */
const onEditOrder = async (event, index) => {
  // if (event.OrderPrice <= event.oldOrderPrice && event.OrderCount <= event.oldOrderCount) {
  // 	ElMessage.warning('出价不能低于原来的出价或竞购量不能低于原来的竞购量')
  // 	return
  // }
  if (index == 0 && event.OrderPrice <= event.oldOrderPrice) {
    ElMessage.warning('出价不能低于原来的出价');
    return;
  }
  if (index == 1 && event.OrderCount <= event.oldOrderCount) {
    ElMessage.warning('竞购量不能低于原来的竞购量');
    return;
  }
  const params = { id: event.Id, orderPrice: event.OrderPrice, orderCount: event.OrderCount };
  try {
    const { data } = await updateOrderBidSave(params);
    if (data.code == 0) {
      ElMessage.success('修改成功');
      userInfo.getMemberInfo();
    }
  } finally {
    getBidList();
  }
};

function updateData(params) {
  getBidList();
}

defineExpose({ updateData });
</script>

<style lang="scss" scoped>
@import '@/assets/style/same.scss';
</style>
