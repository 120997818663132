<template>
  <el-dialog v-model="dialogVisible" :modal="false" :width="500" :close-on-click-modal="false" title="会员充值">
    <div class="pay">
      <div class="pay-title">请您及时充值，以便尽快到帐订购！</div>
      <div class="pay-content">
        <div style="line-height: 30px">
          <div>账号：{{ userInfo.memberDetailInfo.memberName }}</div>
          <div>单号：{{ payInfo.orderNo }}</div>
          <div>金额：{{ payInfo.orderAmount }}</div>
          <div
            >状态：<el-text :type="payStatus[key].type">{{ payStatus[key].text }}</el-text></div
          >
        </div>
        <div class="pay-content__right">
          <QrcodeVue :value="payInfo.qrUrl" :size="180"></QrcodeVue>
          <div style="margin-top: 5px">扫码支付</div>
        </div>
      </div>
      <div class="pay-footer">
        <div class="pay-footer__text">
          支付完成前请不要关闭此支付验证窗口<br />支付完成后，请根据您支付的情况点击右边按钮。</div
        >
        <el-button type="primary" @click="getPayResult">我已支付</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref } from 'vue';
import { throttle } from '@/common/index';
import QrcodeVue from 'qrcode.vue';
import { ElMessage } from 'element-plus';
import { userStore } from '@/store/user';
import { getOrderRecharge } from '@/api/productSale';

const emit = defineEmits(['update']);

const userInfo = userStore();
const payInfo = ref({});
const dialogVisible = ref(false);
const onChangeVisible = async event => {
  payInfo.value = event;
  dialogVisible.value = true;
};

const payStatus = ref({
  0: { text: '已支付', type: 'success' },
  1: { text: '未支付', type: 'warning' }
});
const key = ref(1);
const getPayResult = throttle(async () => {
  try {
    const { data } = await getOrderRecharge(payInfo.value.orderNo);
    if (data.paid) {
      key.value = 0;
      await userInfo.getMemberInfo();
      dialogVisible.value = false;
      emit('update');
    } else {
      key.value = 1;
      ElMessage.warning('您还未支付或正在支付中...');
    }
  } catch (e) {
    key.value = 1;
    ElMessage.warning('您还未支付或正在支付中...');
  }
}, 2000);

defineExpose({
  onChangeVisible
});
</script>

<style lang="scss" scoped>
.pay {
  padding: 10px;
  border: solid 1px lightgrey;

  &-title {
    font-size: 24px;
    color: red;
    text-align: center;
  }

  &-content {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    &__right {
      margin-left: 10px;
      text-align: center;
    }

    &__text {
      color: red;
      margin-left: 20px;
    }
  }

  &-footer {
    display: flex;
    margin-top: 20px;

    &__text {
      font-size: 12px;
      color: red;
    }
  }
}
</style>
