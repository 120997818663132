<template>
  <view class="u-m-t-5">
    <el-tabs type="border-card" :stretch="true" :model-value="activeName" @update:modelValue="onUpdateActiveName">
      <el-tab-pane label="已购货单" name="purchaseOrder">
        <purchaseOrder
          ref="purchaseOrderRef"
          :memberInfo="userInfo.memberDetailInfo"
          :show="activeName == 'purchaseOrder'"
        />
      </el-tab-pane>
      <el-tab-pane label="竞购订单" name="biddingOrder">
        <bidOrder ref="bidOrderRef" :memberInfo="userInfo.memberDetailInfo" :show="activeName == 'biddingOrder'" />
      </el-tab-pane>
      <el-tab-pane label="预购订单" name="bookingOrder">
        <bookOrder ref="bookinOrderRef" :memberInfo="userInfo.memberDetailInfo" :show="activeName == 'bookingOrder'" />
      </el-tab-pane>
      <el-tab-pane label="晚场订单" name="presaleOrder">
        <presaleOrder ref="presaleOrderRef" :show="activeName == 'presaleOrder'" />
      </el-tab-pane>
    </el-tabs>
  </view>
</template>

<script setup>
import purchaseOrder from './components/purchase-order/index.vue';
import bidOrder from './components/bid-order/index.vue';
import bookOrder from './components/book-order/index.vue';
import presaleOrder from './components/presale-order/index.vue';
import { userStore } from '@/store/user';
import { ref, onMounted, onUnmounted, watch } from 'vue';

const userInfo = userStore();

const props = defineProps({
  activeName: {
    type: String,
    default: 'purchaseOrder'
  }
});

const emit = defineEmits(['update:activeName']);

function onUpdateActiveName(event) {
  emit('update:activeName', event);
}

watch(
  () => userInfo.memberDetailInfo,
  (newVal, oldVal) => {
    console.log('sdfsdfsdfsdfsd');
    updateData();
  }
);

watch(
  () => props.activeName,
  (newVal, oldVal) => {
    updateData();
  }
);

const purchaseOrderRef = ref(null);
const bidOrderRef = ref(null);
const bookinOrderRef = ref(null);
const presaleOrderRef = ref(null);

function updateData() {
  console.log('updateData', props.activeName);
  if (props.activeName == 'purchaseOrder') {
    purchaseOrderRef.value.updateData();
  } else if (props.activeName == 'biddingOrder') {
    bidOrderRef.value.updateData();
  } else if (props.activeName == 'bookingOrder') {
    bookinOrderRef.value.updateData();
  } else if (props.activeName == 'presaleOrder') {
    presaleOrderRef.value.updateData();
  }
}
</script>
