<template>
	<div class="w">
		<div class="nav-box">
			<router-link to="/notice" active-class="selected-link">市场公告</router-link>
		</div>
		<div class="notice-wrap">
			<el-scrollbar max-height="70vh" always>
				<div class="tips">
					<div>尊敬的客户：您好！</div>
					<div>请您一定花时间阅读下面的重要内容，这关系到后面订购及物流等问题，如有疑问可联系您的客户经理，谢谢您</div>
				</div>
				<div class="notice-box" v-if="noticeList.length > 0">
					<div v-for="(item, index) in noticeList" :key="index" class="notice-list u-m-t-15">
						<div class="notice-title">
							<div>
								<div class="title">{{item.Title}}</div>
								<div class="time">{{moment(item.ModifyTime).format('YYYY-MM-DD')}}</div>
							</div>
							<img src="@/assets/image/noticebg.png" class="notice-img">
						</div>
						<div class="content" v-html="item.Content"></div>
					</div>
				</div>
				<div v-else class="u-m-t-50 notice-null">当前没有市场公告!!!</div>
			</el-scrollbar>
		</div>
		<div class="btn-wrap">
			<el-checkbox v-model="isAgree" size="large">已阅读市场公告</el-checkbox>
			<div class="btn">
				<el-button type="primary" size="large" @click="enter()">进入系统</el-button>
				<el-button size="large" style="margin-left: 30px !important;" @click="logout">退出系统</el-button>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {ref,reactive,onMounted,onBeforeUnmount} from "vue";
	import {userStore} from '@/store/user';
	import {useRouter} from "vue-router";
	import {ElMessage} from 'element-plus';
	import {articleListByType} from '@/api/article';
	import moment from 'moment';
	const userInfo = userStore();
	// 进入VUE时首先调用的钩子函数
	onMounted(() => {
		//添加背景图片
		document.body.style.height = '100vh';
		document.body.style.width = '100vw';
		document.body.style.overflow = 'hidden';
		document.body.style.backgroundRepeat = 'no-repeat';
		document.body.style.backgroundPosition = 'center center';
		document.body.style.backgroundSize = 'cover';
		document.body.style.backgroundImage = 'url(' + require('../../assets/image/bg.png') + ')';
		getNoticeList();
	});
	// 离开VUE时调用的钩子函数
	onBeforeUnmount(() => {
		// 背景色为白色
		document.body.style.backgroundImage = '';
		document.body.style.overflowY = 'auto';
	});

	const noticeList = ref([]);
	const isAgree = ref();
	const router = useRouter();
	
	const getNoticeList = () =>{
		return articleListByType({articleType:1}).then(res => {
			console.log('data',res.records)
			noticeList.value = res.data.records;
		})
	}
	
	
	
	//进入系统
	const enter = () => {
		if (!isAgree.value) {
			ElMessage({
				message: '请勾选已阅读市场公告',
				type: 'error',
				duration:1000
			})
			return
		} 
		router.push({
			path: '/index/home'
		})
	};
	//退出登录
	const logout = () => {
		userInfo.memberInfo='';
		router.push({
			path:'/login'
		});
	};
</script>

<style scoped>
	.content >>> p > img {
		width: 200px;
	}
</style>
<style lang="scss" scoped>
	.w {
		margin:30px auto;
		min-height:800px;
		.nav-box {
			margin-bottom: 30px;
			display: flex;
			justify-content: center;
			align-items: center;

			a,
			.el-button.is-text {
				display: inline-block;
				font-size: 22px;
				font-weight: 400;
				color: #333333;
				margin-right: 30px;
			}

			.selected-link {
				color: #FC4D9A;
				border-bottom: 4px solid #FE5BA3;
			}

			.el-button.is-text {
				background-color: none;
				padding: 0px;
			}

			.el-button.is-text:not(.is-disabled):hover,
			.el-button.is-text:not(.is-disabled):focus {
				background-color: rgba(255, 255, 255, 0);
				border: none;
			}
		}

		.notice-wrap {
			box-shadow: 0px 0px 5px #DDDDDD;
			padding: 20px 0px 0px 50px;

			.tips {
				font-size: 20px;
				font-weight: 400;
				color: #333333;
				line-height: 40px;
			}

			.notice-box {
				width:100%;
				height:100%;
				box-sizing: border-box;
				display: grid;
				grid-gap: 30px;
				grid-template-columns: repeat(2,1fr);
				margin-bottom: 10px;
				.notice-list {
					width: 600px;
					height: auto;
					border-radius: 10px;
					box-shadow: 0px 0px 5px #DDDDDD;
					background-color: #FFFFFF;
					padding: 20px;

					.notice-title {
						display: flex;
						justify-content: space-between;
						align-items: center;
						height:auto;
						.title {
							padding-right: 20px;
							color: #079fff;
							font-size: 24px;
						}
						.time{
							font-size:14px;
							color:#BBB;
							margin-top:5px;
						}
						.notice-img {
							width: 110px;
							height: 88px;
						}
					}

					.content {
						margin-top:15px;
						font-size: 22px;
						color: #333333;
					}
				}
			}

			.notice-list:nth-child(2n+1) {
				margin-left: 40px;
			}
			.notice-null{
				font-size:28px;
				font-weight: bold;
				color:#333333;
			}
		}

		.btn-wrap {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 20px;

		}
		.el-checkbox.el-checkbox--large .el-checkbox__label{
			font-size: 16px;
		}
	}
</style>