const suggestions = [
  { value: "玫瑰" },
  { value: "百合" },
  { value: "空气凤梨" },
  { value: "洋桔梗" },
  { value: "康乃馨" },
  { value: "向日葵" },
  { value: "配花" },
  { value: "人工花材" },
  { value: "艺菊花" },
  { value: "球根花卉" },
]

export const querySearch = (queryString, cb) => {
  const results = queryString
    ? suggestions.filter(createFilter(queryString))
    : suggestions
  // call callback function to return suggestions
  cb(results)
}

const createFilter = (queryString) => {
  return (item) => {
    return (
      item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
    )
  }
}


