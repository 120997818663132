<template>
	<el-dialog v-model="dialogVisible" :close-on-click-modal="false" title="投诉信息" draggable width="1000px">
		<el-form :model="complaintForm" ref="complaintFormRef" label-width="80px">
			<el-form-item label="订单编号:">
				<span style="color:#333333;">{{complaintForm.orderNo}}</span>
			</el-form-item>
			<el-form-item label="售后产品:">
				<span style="color:#333333;">{{complaintForm.brand+complaintForm.productName+complaintForm.grade}}</span>
			</el-form-item>
			<el-form-item label="申请原因:">
				<span style="color:#333333;">{{complaintForm.complainReason}}</span>
			</el-form-item>
			<el-form-item label="申请数量(扎):">
				<span style="color:#333333;">{{complaintForm.complainQuanlity}}</span>
			</el-form-item>
			<el-form-item label="投诉备注:">
				<span style="color:#333333;">{{complaintForm.complainRemark}}</span>
			</el-form-item>
			<el-form-item label="图片">
				<div class="img-list" v-if="complaintForm.fileList.length > 0">
					<div class="img-item" v-for="(item, index) in complaintForm.fileList" :key="index"
						@click="handlePictureCardPreview(item.url)">
						<img :src="item.url">
					</div>
				</div>
				<el-dialog v-model="dialogVisibleImg" width="630px" title="查看图片">
					<div style="">
						<img :src="dialogImageUrl" alt="Preview Image" style="max-width:600px;"/>
					</div>
				</el-dialog>
			</el-form-item>
			</el-form>
			<!-- 赔付明细 -->
			<el-divider content-position="left" style='margin-top:30px;margin-bottom: 30px;'>赔付明细</el-divider>
			<el-table :data="state.data" border style="width: 100%" v-loading="state.loading" empty-text="没有找到相关信息！"
				:header-cell-style="{ background:'#f2f2f2',color:'#3a3a3a'}">
				<el-table-column prop="compensateTime" label="赔付时间" align="center"></el-table-column>
				<el-table-column prop="compensateCount" label="赔付数量" align="center"></el-table-column>
				<el-table-column prop="compensateMoney" label="赔付金额" align="center">
					<template #default="scope">
						<span>{{ scope.row.compensateMoney.toFixed(2) }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="compensateReason" label="赔付原因" align="center"></el-table-column>
				<el-table-column prop="compensateRemark" label="赔付备注" align="center"></el-table-column>
				<!-- <el-table-column prop="supName" label="供应商" align="center"></el-table-column>
				<el-table-column prop="collectName" label="集货站" align="center"></el-table-column>
				<el-table-column prop="DelivName" label="配送站" align="center"></el-table-column> -->
				<el-table-column prop="compensatorType" label="赔付方类型" align="center">
					<template #default="scope">
						<span v-if="scope.row.compensatorType==1">供货商</span>
						<span v-if="scope.row.compensatorType==2">集货站</span>
						<span v-if="scope.row.compensatorType==3">配送站</span>
					</template>
				</el-table-column>
				<el-table-column prop="compensateImg" label="备注图片 " align="center">
					<template #default="scope">
						<div  @click="showCompensateImg(scope.row)">
							<el-image v-if="scope.row.compensateImg!==''" :src="handlerImgUrl(scope.row.scompensateImg)" style="width:30px; height:30px;"></el-image>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<el-dialog v-model="dialogVisibleCompensateImg" width="630px" title="查看图片">
				<div style="">
					<img :src="compensateImgUrl" alt="Preview Image" style="max-width:600px;"/>
				</div>
			</el-dialog>
	</el-dialog>
</template>

<script setup>
	import {reactive,ref} from 'vue';
	import {ElMessage} from 'element-plus'
	import {handlerImgUrl} from "@/common/index";
	import {webOrderComplainInfo} from '@/api/user';
	const emit = defineEmits(['update'])
	
	// 获取表单对象
	const complaintFormRef=ref();
	const complaintForm=reactive({
		id:'',
		orderNo:'',
		brand:'',
		productName:'',
		grade:'',
		complainReason:'',
		complainQuanlity:0,
		complainRemark:'',
		imgs:'',
		fileList:[]
	});
	
	//赔付详情数据源
	const state = reactive({
		data: [], //表格数据
		loading: true
	})
	
	// 预览图片
	const dialogVisibleImg = ref(false)
	const dialogImageUrl = ref('')
	const handlePictureCardPreview = (event) => {
		dialogImageUrl.value = event
		dialogVisibleImg.value = true
	}
	
	
	/**
	 * 获取商品评价信息
	 * @param {无}
	 */
	const getOrderComplainInfo=()=>{
		state.loading = true;
		return webOrderComplainInfo({id:complaintForm.id}).then(res => {
			let orderComplainInfo=res.data.orderComplainInfo;
			complaintForm.id=orderComplainInfo.id;
			complaintForm.orderNo=orderComplainInfo.orderNum;
			complaintForm.complainReason=orderComplainInfo.complainReason;
			complaintForm.complainQuanlity=orderComplainInfo.complainQuanlity;
			complaintForm.complainRemark=orderComplainInfo.complainRemark;
			complaintForm.imgs=orderComplainInfo.imgs;
			let files=complaintForm.imgs.split(",");
			let tmpFilesList = [];
			files.forEach((e)=>{
				if(e){
					tmpFilesList.push({
						url:handlerImgUrl(e)
					})
				}
			});
			complaintForm.fileList=tmpFilesList;
			state.data=res.data.complainConfirmList;
			state.loading = false;
		})
	}
	
	// 预览售后处理表格图片
	const dialogVisibleCompensateImg = ref(false)
	const compensateImgUrl = ref('')
	const showCompensateImg = (event) => {
		compensateImgUrl.value = event
		dialogVisibleCompensateImg.value = true
	}
	
	/**
	 * 弹窗显示
	 * @param {无}
	 */
	const dialogVisible = ref(false);
	const onChangeVisible = (event) => {
		complaintForm.id=event.Id;
		complaintForm.brand=event.Brand
		complaintForm.productName=event.ProductName
		complaintForm.grade=event.Grade
		getOrderComplainInfo();
		dialogVisible.value = true;
	};
	defineExpose({
		onChangeVisible
	});
</script>

<style lang="scss">
	.myMoney{
		.el-input-number .el-input__inner{
			text-align: left !important;
		}
	}
</style>
<style lang="scss" scoped>
	.img-list {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	
		.img-item {
			position: relative;
			width: 145px;
			height: 145px;
			border-radius: 10px;
			margin: 0 10px 10px 0;
			border: 1px solid #cdd0d6;
	
			img {
				width: 100%;
				height: 100%;
			}
		}
	
		.icon-item {
			position: absolute;
			top: 4px;
			right: 4px;
			cursor: pointer;
		}
		
	}
</style>