<template>
  <router-view></router-view>
  <!-- 底部版权 -->
  <my-footer></my-footer>
  <suspended-menu></suspended-menu>
</template>
<script setup>
import MyFooter from "@/components/myFooter.vue";
import suspendedMenu from "@/components/suspendedMenu.vue";
import { computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
</script>
<style lang="scss">
//引入重置样式
@import "assets/style/reset.scss";
@import "assets/style/animation.css";
</style>
