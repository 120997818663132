<template>
	<!-- 搜索栏 -->
	<el-form ref="formRef" :model="searchForm" style="display:flex;">
		<el-form-item label="日期：" pro="complainTime" class="u-m-r-15">
			<el-date-picker v-model="searchForm.complainTime" type="date" placeholder="请选择意见建议日期" format="YYYY-MM-DD" />
		</el-form-item>
		<el-button type="primary" @click="onSearch">查询</el-button>
		<el-button type="primary" style="margin-left: 15px !important;" @click="onlineMessageRef.onChangeVisable()">新增意见建议</el-button>
	</el-form>
	<el-table :data="messageTable.value" border v-loading="loading"
		empty-text="没有找到相关信息！" table-layout="auto" size="small" style="width:100%" :header-cell-style="{ background:'#f2f2f2',color:'#3a3a3a'}">
		<el-table-column prop="CreateTime" label="时间" align="center"></el-table-column>
		<el-table-column prop="ComplainText" label="投诉内容" align="center"></el-table-column>
		<el-table-column prop="AnswerText" label="回复" align="center"></el-table-column>
		<el-table-column prop="AnswerTime" label="回复时间" align="center"></el-table-column>
		<!-- <el-table-column prop="operate" label="操作" align="center" width="60"></el-table-column> -->
	</el-table>
	<div class="u-pagination">
		<el-pagination background :current-page="searchForm.limit.conditions.pageStart" :page-size="searchForm.limit.conditions.pageLength" layout="prev, pager, next"
			prev-text="上一页" next-text="下一页" :total="total" @current-change="handleCurrentChange"
			@size-change="handleSizeChange"></el-pagination>
	</div>

	<!-- 提交投诉窗口 -->
	<onlineMessageDialog ref="onlineMessageRef" @update="getComplain"></onlineMessageDialog>
</template>

<script setup>
	import { ref, reactive, onMounted } from "vue";
	import { ElMessage } from 'element-plus'
	import {
		getComplainList
	} from '@/api/productSale'
	import onlineMessageDialog from './onlineMessage.vue'
	import moment from "moment";
	
	const onlineMessageRef = ref()
	
	onMounted(() => {
		getComplain()
	})

	/**
	 * 取投诉列表
	 * @param {无}
	 */
	const searchForm = reactive({ 
		complainTime: '',
		complainType: 2,
		limit: {							// 分页参数
			enable: true,
			conditions: {
				pageLength: 15, 
				pageStart: 1
			}
		}
	});
	const loading = ref(true)
	const messageTable = reactive([]);
	const total = ref(0)
	const getComplain = async () => {
		loading.value = true
		const { data: { filterAmount, records } } = await getComplainList(searchForm)
		messageTable.value = records
		total.value = filterAmount
		loading.value = false
	}

	/**
	 * 查询
	 * @param {无}
	 */
	const onSearch = () => {
		searchForm.complainTime = searchForm.complainTime ? moment(searchForm.complainTime).format('YYYY-MM-DD') : ''
		getComplain()
	}

	/**
	 * 改变页码
	 * @param {number} val 
	 */
	const handleCurrentChange = (val) => {
		searchForm.limit.conditions.pageStart = val;
		getComplain()
	}

	/**
	 * 改变页数限制
	 * @param {number} val 
	 */
	const handleSizeChange = (val) => {
		searchForm.limit.conditions.pageLength = val;
		getComplain()
	}
</script>

<style lang="scss" scoped>
</style>
