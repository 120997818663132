<template>
  <el-dialog v-model="dialogVisible" title="用户留言" width="900px" :draggable="true">
    <!-- 搜索栏 -->
    <el-form ref="formRef" :model="searchForm" style="display:flex;">
      <el-form-item label="日期：" pro="complainTime" class="u-m-r-15">
        <el-date-picker v-model="searchForm.complainTime" type="date" placeholder="请选择留言日期" format="YYYY-MM-DD" />
      </el-form-item>
      <el-button type="primary" @click="onSearch">查询</el-button>
      <el-button type="primary" style="margin-left: 15px !important;" @click="onCreateMessage">新增用户留言</el-button>
    </el-form>
    <el-table :data="messageTable.value" border v-loading="loading" empty-text="没有找到相关信息！" table-layout="auto"
      size="small" style="width:100%">
      <el-table-column prop="CreateTime" label="时间" align="center"></el-table-column>
      <el-table-column prop="ComplainText" label="留言内容" align="center"></el-table-column>
      <el-table-column label="图片" align="center">
        <template #default="scope">
          <el-image style="width: 50px; height: 50px" :src="handlerImgUrl(scope.row.PicUrl)" preview-teleported
            :preview-src-list="[handlerImgUrl(scope.row.PicUrl)]"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="AnswerText" label="回复" align="center"></el-table-column>
      <el-table-column prop="AnswerTime" label="回复时间" align="center"></el-table-column>
    </el-table>
    <div class="u-pagination">
      <el-pagination background :current-page="searchForm.limit.conditions.pageStart"
        :page-size="searchForm.limit.conditions.pageLength" layout="prev, pager, next" prev-text="上一页" next-text="下一页"
        :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange"></el-pagination>
    </div>
  </el-dialog>

  <!-- 提交留言窗口 -->
  <orderLeaveAMessage ref="onlineMessageRef" @update="getComplain"></orderLeaveAMessage>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { ElMessage } from 'element-plus'
import { handlerImgUrl } from "@/common/index";
import {
  getComplainList
} from '@/api/productSale'
import orderLeaveAMessage from '../order-leave-a-massage/index.vue'
import moment from "moment";

/**
 * 提交留言弹窗
 * @param {无}
 */
const onlineMessageRef = ref()
const onCreateMessage = () => {
  onlineMessageRef.value.onChangeVisible()
}

/**
 * 取留言列表
 * @param {无}
 */
const searchForm = reactive({
  complainTime: '',
  complainType: 1,
  limit: {							// 分页参数
    enable: true,
    conditions: {
      pageLength: 15,
      pageStart: 1
    }
  }
});
const loading = ref(true)
const messageTable = reactive([]);
const total = ref(0)
const getComplain = async () => {
  loading.value = true
  const { data: { filterAmount, records } } = await getComplainList(searchForm)
  messageTable.value = records
  total.value = filterAmount
  loading.value = false
}

/**
 * 查询
 * @param {无}
 */
const onSearch = () => {
  searchForm.complainTime = searchForm.complainTime ? moment(searchForm.complainTime).format('YYYY-MM-DD') : ''
  getComplain()
}

/**
 * 改变页码
 * @param {number} val 
 */
const handleCurrentChange = (val) => {
  searchForm.limit.conditions.pageStart = val;
  getComplain()
}

/**
 * 改变页数限制
 * @param {number} val 
 */
const handleSizeChange = (val) => {
  searchForm.limit.conditions.pageLength = val;
  getComplain()
}

const dialogVisible = ref(false)
const onDialogVisible = () => {
  getComplain()
  dialogVisible.value = true
}

defineExpose({
  onDialogVisible
})
</script>

<style lang="scss" scoped></style>
